import { Injectable } from '@angular/core';
import {
  IListado,
  IBarrio,
  IQueryParam,
  ICreateBarrio,
  IUpdateBarrio,
  IGrupo,
  ICreateGrupo,
  IUpdateGrupo,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class GruposService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IGrupo>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/grupos`, { params });
  }

  public listarPorId(id: string): Observable<IGrupo> {
    return this.http.get(`/grupos/${id}`);
  }

  public crear(dato: ICreateGrupo): Observable<IGrupo> {
    return this.http.post(`/grupos`, dato);
  }

  public editar(id: string, dato: IUpdateGrupo): Observable<IGrupo> {
    return this.http.put(`/grupos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/grupos/${id}`);
  }
}
