import { Injectable } from '@angular/core';
import {
  ICrearEvento,
  IEventoExterno,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class EventosExternosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IEventoExterno>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/eventosExternos`, { params });
  }

  public listarPorId(id: string): Observable<IEventoExterno> {
    return this.http.get(`/eventosExternos/${id}`);
  }

  public crear(dato: ICrearEvento): Observable<IEventoExterno> {
    return this.http.post(`/eventosExternos`, dato);
  }

  public editar(id: string, dato: ICrearEvento): Observable<IEventoExterno> {
    return this.http.put(`/eventosExternos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/eventosExternos/${id}`);
  }
}
