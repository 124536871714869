<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Changelog
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <markdown [src]="'/assets/CHANGELOG.md'"></markdown>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <mat-checkbox [(ngModel)]="noMostrar" class="mr-3">
      No volver a mostrar
    </mat-checkbox>
    <button mat-raised-button type="button" (click)="cerrar()">Cerrar</button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <!-- <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer> -->
</mat-card>
