import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { VecinosService } from '../../../entidades/vecinos/vecinos.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
} from 'rxjs/operators';
import { ICoordenadas } from 'modelos/src';
import { firstValueFrom, fromEvent } from 'rxjs';

@Component({
  selector: 'app-direccion',
  templateUrl: './direccion.component.html',
  styleUrls: ['./direccion.component.scss'],
})
export class DireccionComponent implements OnInit {
  public loading = false;
  public localidad?: string;
  public calle?: string;
  public numero?: number;

  public results: string[] = [];
  @Input() coordenadasTelefono?: ICoordenadas;
  @Input() direccion?: string = '';
  @Output() direccionChange = new EventEmitter<string>();

  constructor(private service: VecinosService) {}

  @ViewChild('inputDireccion', { static: true }) inputNumero?: ElementRef;

  public async cambioCalle() {
    await this.placePrediction();
  }
  public async cambioNumero() {
    await this.placePrediction();
  }

  public async cambioDireccion() {
    this.direccionChange.emit(this.direccion);
  }

  public async cambioLocalidad() {
    await this.placePrediction();
  }

  private async placePrediction() {
    if (this.calle && this.numero) {
      this.loading = true;
      try {
        let searchTerm = `${this.calle} ${this.numero}`;
        if (this.localidad) {
          searchTerm += ` ${this.localidad}`;
        }
        this.results = (
          await firstValueFrom(
            this.service.direcciones(searchTerm, this.coordenadasTelefono)
          )
        ).resultados;

        this.direccion = this.results[0];

        this.direccionChange.emit(this.direccion);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    }
  }

  private listenDirChange(): void {
    fromEvent(this.inputNumero?.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        filter(() => !this.loading),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(async (text: string) => {
        await this.placePrediction();
      });
  }

  ngOnChanges() {
    if (this.direccion) {
      this.results = [this.direccion];
    }
  }

  ngOnInit(): void {
    this.listenDirChange();
  }
}
