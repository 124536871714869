import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ICliente } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class EnvsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  private loadStyle(cliente: string) {
    const cliente2 = sessionStorage.getItem('cliente');
    let css = cliente;
    if (cliente2) {
      const cliente3 = JSON.parse(cliente2) as ICliente;
      if (cliente3.tipo === 'Barrio Privado') {
        css = 'barrios_privados';
      }
    }

    let elem = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (elem) {
      elem.href = `${css}.css`;
    } else {
      const head = this.document.getElementsByTagName('head')[0];
      const elem = this.document.createElement('link');
      elem.id = 'client-theme';
      elem.rel = 'stylesheet';
      elem.href = `${css}.css`;
      head.appendChild(elem);
    }
  }
  private loadManifest(cliente: string) {
    let elem = this.document.getElementById(
      'client-manifest'
    ) as HTMLLinkElement;
    if (elem) {
      elem.href = `assets/clientes/${cliente}/manifest.webmanifest`;
    } else {
      const head = this.document.getElementsByTagName('head')[0];
      const elem = this.document.createElement('link');
      elem.id = 'client-manifest';
      elem.rel = 'manifest';
      elem.href = `assets/clientes/${cliente}/manifest.webmanifest`;
      head.appendChild(elem);
    }
  }
  private loadColor(color?: string) {
    if (color) {
      const elem = this.document.getElementById(
        'client-color'
      ) as HTMLMetaElement;
      if (elem) {
        elem.content = color;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('meta');
        elem.id = 'client-color';
        elem.name = 'theme-color';
        elem.content = color;
        head.appendChild(elem);
      }
    }
  }
  private loadTitle(cliente?: string) {
    if (cliente) {
      cliente = cliente.split('_').join(' ').toUpperCase();
      const elem = this.document.getElementById(
        'client-title'
      ) as HTMLTitleElement;
      if (elem) {
        elem.text = `Monitoreo ${cliente}`;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('title');
        elem.id = 'client-title';
        elem.text = `Monitoreo ${cliente}`;
        head.appendChild(elem);
      }
    }
  }
  private loadIcon(iconUrl?: string) {
    if (iconUrl) {
      const elem = this.document.getElementById(
        'client-icon'
      ) as HTMLLinkElement;
      if (elem) {
        elem.href = iconUrl;
      } else {
        const head = this.document.getElementsByTagName('head')[0];
        const elem = this.document.createElement('link');
        elem.id = 'client-icon';
        elem.href = iconUrl;
        head.appendChild(elem);
      }
    } else {
      console.log('No se ha definido el icono');
    }
  }

  public loadCliente(cliente: string, color?: string, iconUrl?: string) {
    if (cliente) {
      console.log('loadCliente', cliente);
      this.loadManifest(cliente);
      this.loadStyle(cliente);
      this.loadColor(color);
      this.loadTitle(cliente);
      this.loadIcon(iconUrl);
    } else {
      console.warn('No se ha definido el cliente');
    }
  }
}
