var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// index.ts
import { degreesToRadians, lengthToRadians, point, radiansToDegrees } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
function destination(origin, distance, bearing, options = {}) {
  const coordinates1 = getCoord(origin);
  const longitude1 = degreesToRadians(coordinates1[0]);
  const latitude1 = degreesToRadians(coordinates1[1]);
  const bearingRad = degreesToRadians(bearing);
  const radians = lengthToRadians(distance, options.units);
  const latitude2 = Math.asin(Math.sin(latitude1) * Math.cos(radians) + Math.cos(latitude1) * Math.sin(radians) * Math.cos(bearingRad));
  const longitude2 = longitude1 + Math.atan2(Math.sin(bearingRad) * Math.sin(radians) * Math.cos(latitude1), Math.cos(radians) - Math.sin(latitude1) * Math.sin(latitude2));
  const lng = radiansToDegrees(longitude2);
  const lat = radiansToDegrees(latitude2);
  return point([lng, lat], options.properties);
}
__name(destination, "destination");
var turf_destination_default = destination;
export { turf_destination_default as default, destination };
