/**
 * RGB space.
 *
 * @module  color-space/rgb
 */

export default {
  name: 'rgb',
  min: [0, 0, 0],
  max: [255, 255, 255],
  channel: ['red', 'green', 'blue'],
  alias: ['RGB']
};