/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Injectable } from '@angular/core';
import {
  IAlerta,
  ICategoria,
  IConfigVecino,
  ICoordenadas,
  IQueryParam,
  Rol,
} from 'modelos/src';
import { NotificationsService } from 'angular2-notifications';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { StoreService } from 'src/app/entidades/login/store.service';

import * as turf from '@turf/turf';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSize = parseInt(localStorage.getItem('pageSize') || '15');
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];

  static phoneCodes = [
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: '---------------',
      dial_code: '',
      code: 'AR',
      disabled: true,
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL',
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO',
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC',
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX',
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE',
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY',
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      dial_code: '+58',
      code: 'VE',
    },
  ];

  static dialogConfig(data?: any): MatDialogConfig {
    return {
      width: '1200px',
      disableClose: true,
      data,
      panelClass: 'custom-dialog',
    };
  }

  constructor(
    private notificationsService: NotificationsService,
    private store: StoreService,
  ) {}

  public pageEvent($event: any): void {
    localStorage.setItem('pageSize', $event.pageSize);
    this.pageSize = $event.pageSize;
  }

  public getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  public categoriaVecino(config: IConfigVecino): string {
    if (config.categoria?.categoria && config.categoria?.hasta) {
      const timeNow = new Date();
      const timeCategoria = new Date(config.categoria.hasta);
      if (timeNow < timeCategoria) {
        return config.categoria.categoria?.nombre!;
      }
    }
    return config.cliente?.categoriaDefault?.nombre || 'Sin categoría';
  }

  public getVencimientoCategoria(config: IConfigVecino): string {
    if (config.categoria?.hasta) {
      const timeNow = new Date();
      const timeCategoria = new Date(config.categoria.hasta);
      if (timeNow < timeCategoria) {
        return config.categoria.hasta;
      }
    }
    return '';
  }

  public getCategoriaVecino(config: IConfigVecino): ICategoria {
    if (config.categoria?.categoria && config.categoria?.hasta) {
      const timeNow = new Date();
      const timeCategoria = new Date(config.categoria.hasta);
      if (timeNow < timeCategoria) {
        return config.categoria.categoria;
      }
    }
    return config.cliente?.categoriaDefault!;
  }

  public getCategoriaConfigVecino(vecino: IConfigVecino): ICategoria {
    if (vecino.categoria?.categoria && vecino.categoria?.hasta) {
      const timeNow = new Date();
      const timeCategoria = new Date(vecino.categoria.hasta);
      if (timeNow < timeCategoria) {
        return vecino.categoria.categoria;
      }
    }
    return vecino.cliente?.categoriaDefault!;
  }

  // public calcularEdad(vecino: IVecino): number {
  //   if (vecino?.fechaNacimiento) {
  //     const date = new Date().getTime();
  //     const nacimiento = new Date(vecino?.fechaNacimiento as string).getTime();
  //     const edad = (date - nacimiento) / 1000 / 60 / 60 / 24 / 365;
  //     return Math.trunc(edad);
  //   }
  //   return 0;
  // }

  public calcularEdadConfig(config: IConfigVecino): number {
    const fechaNacimiento = config?.datosPersonales?.fechaNacimiento;
    if (fechaNacimiento) {
      const date = new Date().getTime();
      const nacimiento = new Date(fechaNacimiento).getTime();
      const edad = (date - nacimiento) / 1000 / 60 / 60 / 24 / 365;
      return Math.trunc(edad);
    }
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public prioridadAlerta(alerta: IAlerta): string {
    // const prioridades = ['Cero', 'Violencia de Género', 'Dos', 'Normal']
    // return prioridades[alerta.prioridad || 3];
    return 'Normal';
  }

  public DesdeHastaISO(diasAtrasDesde: number, diasAtrasHasta: number = 0) {
    const date = new Date();
    date.setDate(date.getDate() - diasAtrasHasta);
    date.setHours(23, 59, 59, 999);
    const hasta = date.toISOString();
    date.setDate(date.getDate() - diasAtrasDesde);
    date.setHours(0, 0, 0, 0);
    const desde = date.toISOString();
    return { desde, hasta };
  }

  public rssiToText(rssi?: number) {
    if (rssi) {
      if (rssi >= -73) {
        return {
          label: 'Excelente',
          background: '#00FF00',
        };
      } else if (rssi >= -83) {
        return {
          label: 'Buena',
          background: '#FFFF00',
        };
      } else if (rssi >= -93) {
        return {
          label: 'Regular',
          background: '#FFA500',
        };
      } else {
        return {
          label: 'Mala',
          background: '#FF0000',
        };
      }
    }
    return {
      label: '',
      background: '#FFFFFF',
    };
  }

  //

  public nombreClienteToClienteFilename(nombreCliente?: string): string {
    if (nombreCliente) {
      const fileName = nombreCliente
        ? nombreCliente
            .split(' ')
            .join('_')
            .split('á')
            .join('a')
            .split('é')
            .join('e')
            .split('í')
            .join('i')
            .split('ó')
            .join('o')
            .split('ú')
            .join('u')
            .split('Á')
            .join('A')
            .split('É')
            .join('E')
            .split('Í')
            .join('I')
            .split('Ó')
            .join('O')
            .split('Ú')
            .join('U')
            .toLowerCase()
        : '';
      return fileName;
    }
    return 'admin';
  }

  //
  private padZero(str: string, len: number = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  public invertColor(hex?: string, bw: boolean = true) {
    if (!hex) {
      return '#000000';
    }
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    const r_str = (255 - r).toString(16);
    const g_str = (255 - g).toString(16);
    const b_str = (255 - b).toString(16);
    // pad each with zeros and return
    return (
      '#' + this.padZero(r_str) + this.padZero(g_str) + this.padZero(b_str)
    );
  }

  // Notif

  public notifError(error: any): void {
    const mensaje =
      error?.error?.mensaje ||
      error?.mensaje ||
      error?.error?.message ||
      error?.message ||
      'Error desconocido, ver logs';
    console.error(error);
    this.notificationsService.error(mensaje);
  }

  public notifSuccess(mensaje: string): void {
    this.notificationsService.success(mensaje);
  }
  public notifWarn(mensaje: string): void {
    this.notificationsService.warn(mensaje);
  }

  public hexToRgb(hex: string) {
    if (hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    } else {
      return '';
    }
  }

  // Fechas

  /**
   * Valida que la fecha sea válida con formato d/m/yyyy
   * @param fecha fecha string en formato d/m/yyyy
   */
  public validarFecha(fecha: string) {
    const valid = moment(fecha, 'D/M/YYYY', true).isValid();
    const valid2 = moment(fecha, 'D-M-YYYY', true).isValid();
    return valid || valid2;
  }

  /**
   * Valida que la hora sea válida con formato h:m
   * @param hora hora string en formato h:m
   */
  public validarHora(hora: string) {
    const valid = moment(hora, 'H:m', true).isValid();
    return valid;
  }

  public fechaYHora(fechaIsoString: string) {
    const timestamp = Date.parse(fechaIsoString);
    if (isNaN(timestamp)) {
      return 'Fecha Inválida';
    } else {
      return new Date(timestamp).toLocaleString();
    }
  }

  public hoyAnioMesDia() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0` + month : month}-${
      day < 10 ? `0` + day : day
    }`;
  }

  public timestampsDesdeHasta(
    diasAtrasDesde: number,
    diasAtrasHasta: number = 0,
  ) {
    const date = new Date();
    date.setDate(date.getDate() + 1 - diasAtrasHasta);
    date.setHours(0, 0, 0, 0);
    const hasta = date.getTime();
    date.setDate(date.getDate() - diasAtrasDesde);
    const desde = date.getTime();
    return { desde, hasta };
  }

  public tiempoTranscurrido(timestamp: string): string {
    if (timestamp) {
      return moment(moment.utc(timestamp), 'YYYYMMDD').fromNow();
    }
    return 'Indeterminado';
  }

  public msToTime(ms: number): string {
    if (+ms) {
      const d = moment.duration(ms);
      const hours = Math.floor(d.asHours());
      if (hours) {
        return `${hours}h ${d.minutes()}m`;
      } else {
        const minutes = Math.floor(d.asMinutes()) - hours * 60;
        if (minutes) {
          return `${minutes}m ${d.seconds()}s`;
        } else {
          const seconds = Math.floor(d.asSeconds()) - minutes * 60;
          return `${seconds}s`;
        }
      }
    }
    return 'Sin tiempo';
  }

  // Mapa y coordenadas

  public async getCurrentPosition(): Promise<ICoordenadas> {
    return new Promise((resolve) => {
      const ubicacionBase: ICoordenadas = {
        lat: -35.5836812,
        lng: -58.0128784,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion: ICoordenadas = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  static async getCurrentPosition(): Promise<ICoordenadas> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const ubicacionBase: ICoordenadas = {
        lat: -35.5836812,
        lng: -58.0128784,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion: ICoordenadas = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  public getCentro(coordenadas?: ICoordenadas[]): ICoordenadas {
    if (coordenadas?.length) {
      const newPolygon: ICoordenadas[] = JSON.parse(
        JSON.stringify(coordenadas),
      );
      const longitudes = newPolygon.map((i) => i.lat);
      const latitudes = newPolygon.map((i) => i.lng);
      latitudes.sort((a, b) => a - b);
      longitudes.sort((a, b) => a - b);
      const lowX = latitudes[0];
      const highX = latitudes[latitudes.length - 1];
      const lowy = longitudes[0];
      const highy = longitudes[latitudes.length - 1];
      const centerX = lowX + (highX - lowX) / 2;
      const centerY = lowy + (highy - lowy) / 2;
      const center: ICoordenadas = {
        lng: centerX,
        lat: centerY,
      };
      return center;
    }
    return { lng: 0, lat: 0 };
  }

  public calcularAreas(polygonos: ICoordenadas[][]): number {
    if (polygonos?.length) {
      let area = 0;
      for (const poly of polygonos) {
        area += this.calcularArea(poly);
      }
      return +area.toFixed(2);
    }
    return 0;
  }

  public calcularArea(coordenadas: ICoordenadas[]): number {
    if (coordenadas?.length > 2) {
      const polygon = this.poliATurf(coordenadas);
      return turf.area(polygon);
    }
    return 0;
  }

  // Roles

  public tieneRol(roles: Rol[]): boolean {
    const user = this.store.getUsuario();
    return !!user.roles?.some((r) => roles.includes(r));
  }

  public enviaMensajes() {
    const usuario = this.store.getUsuario();
    const roles = usuario.roles;
    return roles.includes('admin') || roles.includes('operador');
  }

  public esOperador(roles: Rol[]) {
    const esOperador =
      roles?.includes('operador') || roles?.includes('Operador (Sin Mensajes)');
    return esOperador;
  }

  public userEsOperador() {
    const user = this.store.getUsuario();
    const roles = user?.roles;
    const esOperador =
      roles?.includes('operador') || roles?.includes('Operador (Sin Mensajes)');
    return esOperador;
  }

  // Polígonos
  public polyOverlapsPoly(
    polygon1: ICoordenadas[],
    polygon2: ICoordenadas[],
  ): boolean {
    // Verificar si los poligonos overlapean
    const isInside = turf.booleanOverlap(
      this.poliATurf(polygon1),
      this.poliATurf(polygon2),
    );
    return isInside;
  }

  public polyTouchesPoly(
    polygon1: ICoordenadas[],
    polygon2: ICoordenadas[],
  ): boolean {
    const isTouching = turf.booleanTouches(
      this.poliATurf(polygon1),
      this.poliATurf(polygon2),
    );
    return isTouching;
  }

  public polyDisjointPoly(
    polygon1: ICoordenadas[],
    polygon2: ICoordenadas[],
  ): boolean {
    const disjoitintg = turf.booleanDisjoint(
      this.poliATurf(polygon1),
      this.poliATurf(polygon2),
    );
    return disjoitintg;
  }

  public polyInsidePoly(littlePoly: ICoordenadas[], bigPoly: ICoordenadas[]) {
    // if (!littlePoly) {
    //   return false;
    // }
    // if (!bigPoly) {
    //   return false;
    // }
    // Verifica que el primer poly esté dentro del segundo
    const isInside = turf.booleanWithin(
      this.poliATurf(littlePoly),
      this.poliATurf(bigPoly),
    );
    return isInside;
  }

  // Polígonos private
  private poliATurf(poli: ICoordenadas[]) {
    const polyInvertido = poli.map((c) => [c.lng, c.lat]);
    polyInvertido.push(polyInvertido[0]);
    const turfPoli = turf.polygon([polyInvertido]);
    return turfPoli;
  }

  public pointOutsidePolygons(
    point: ICoordenadas,
    polygons: ICoordenadas[][],
  ): boolean {
    for (const polygon of polygons) {
      if (this.estaDentro(point, polygon)) {
        return false; // El punto está dentro de al menos uno de los polígonos
      }
    }
    return true; // El punto está fuera de todos los polígonos
  }

  public estaDentro(
    ubicacion: ICoordenadas,
    poligono?: ICoordenadas[],
  ): boolean {
    if (ubicacion && poligono) {
      const x = ubicacion.lat;
      const y = ubicacion.lng;
      let inside = false;
      for (let i = 0, j = poligono.length - 1; i < poligono.length; j = i++) {
        const xi = poligono[i].lat;
        const yi = poligono[i].lng;
        const xj = poligono[j].lat;
        const yj = poligono[j].lng;
        const intersect =
          yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
          inside = !inside;
        }
      }
      return inside;
    }
    return false;
  }

  public textToColor(text: string): string {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  public static textToColor(text: string): string {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  public static hexToRgba(hex: string, opacity: number): string {
    // Elimina el símbolo '#' si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor HEX en partes de 2 caracteres
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Si el HEX es del tipo corto (#abc), lo expandimos a 6 caracteres (#aabbcc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Si el HEX ya tiene 6 caracteres
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Retorna el formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  public static colorNameToHex(color: string): string {
    // Crea un elemento temporal en el DOM
    const tempElement = document.createElement('div');

    // Aplica el color al elemento
    tempElement.style.color = color;

    // Agrega el elemento al cuerpo del documento (pero oculto)
    document.body.appendChild(tempElement);

    // Computa el estilo aplicado al elemento
    const computedColor = window.getComputedStyle(tempElement).color;

    // Elimina el elemento temporal
    document.body.removeChild(tempElement);

    // Convierte el valor RGB obtenido a HEX
    const rgbMatch = computedColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+).*\)$/,
    );
    if (!rgbMatch) {
      return HelperService.textToColor(color); // No es un color válido hago uno con las palabra
    }

    const r = parseInt(rgbMatch[1]).toString(16).padStart(2, '0');
    const g = parseInt(rgbMatch[2]).toString(16).padStart(2, '0');
    const b = parseInt(rgbMatch[3]).toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
  }
}
