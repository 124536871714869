import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebSocketBroadcastService } from '../servicios/websocket-broadcast';
import RecordRTC from 'recordrtc';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
})
export class AudioRecorderComponent implements OnInit {
  @Input() chipId = '';

  private mediaStream?: MediaStream;
  private mediaRecorder?: RecordRTC;

  private wsSubscription?: Subscription;

  constructor(private webSocketBroadcastService: WebSocketBroadcastService) {}

  get grabando() {
    return this.mediaRecorder?.getState();
  }

  private supportedMime() {
    const mimes = [
      'audio/webm',
      'audio/ogg',
      'audio/mpeg',
      'audio/mp3',
      'audio/mp4',
      'audio/x-matroska',
    ];
    const mime = mimes.find((mime) => {
      const support = MediaRecorder.isTypeSupported(mime);
      return support;
    });
    return mime;
  }

  private async initMediaStream() {
    const mediaStreamConstraints: MediaStreamConstraints = {
      audio: { channelCount: 1, sampleRate: 22050 },
      video: false,
    };
    this.mediaStream = await navigator.mediaDevices.getUserMedia(
      mediaStreamConstraints
    );
  }

  private async ondataavailable(event: Blob) {
    console.log('ondataavailable', event);
    const blob = event;
    const base64 = await this.blobToBase64(blob);
    console.log('base64', base64);
    console.log('base64 length', base64.length);
    this.webSocketBroadcastService.sendAudio(this.chipId, 'audio/wav', base64);
  }

  private async initMediaRecorder(mime: string) {
    this.mediaRecorder = new RecordRTC(this.mediaStream!, {
      type: 'audio',
      // mimeType: 'audio/webm;codecs=pcm',
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 22050,
      // bitrate: 44100,
      bitsPerSecond: 8,
      // audioBitsPerSecond: 44100,
      recorderType: RecordRTC.StereoAudioRecorder,
      timeSlice: 1000,
      ondataavailable: this.ondataavailable.bind(this),
    });
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }

  private async initAudio() {
    const mime = this.supportedMime();
    if (mime) {
      await this.initMediaStream();
      await this.initMediaRecorder(mime);
    } else {
      alert('Ningún formato de audio compatible');
      throw new Error('Ningún formato de audio compatible');
    }
  }

  public async grabar() {
    await this.initAudio();
    this.webSocketBroadcastService.openWS();
  }

  public stop() {
    this.stopRecording();
    const tracks = this.mediaStream?.getTracks();
    tracks?.forEach((track) => track.stop());
    this.webSocketBroadcastService.closeWS();
  }

  private startRecording() {
    this.mediaRecorder?.startRecording();
    console.log('start recorder state', this.mediaRecorder?.state);
  }

  private stopRecording() {
    this.mediaRecorder?.stopRecording();
    console.log('stop recorder state', this.mediaRecorder?.state);
  }

  // WS

  private suscribeWsUpdates() {
    this.wsSubscription = this.webSocketBroadcastService
      .getMessage()
      .subscribe({
        next: this.handleUpdateResponse.bind(this),
      });
  }

  private handleUpdateResponse(message: any) {
    if (typeof message === 'string') {
      if (message.includes('Sesion WS iniciada')) {
        this.startRecording();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.suscribeWsUpdates();
  }

  ngOnDestroy(): void {
    this.stop();
    this.wsSubscription?.unsubscribe();
  }
}
