import { Injectable } from '@angular/core';
import { ICoordenadas } from 'modelos/src';
import { Observable } from 'rxjs';
import { PAIS } from 'src/environments/contantes';
import { HttpApiService } from './http-api.service';

@Injectable({
  providedIn: 'root',
})
export class DireccionesService {
  constructor(private http: HttpApiService) {}

  public direcciones(
    text: string,
    coordenadas?: ICoordenadas,
  ): Observable<{ resultados: string[] }> {
    return this.http.post(`/geocode/direcciones`, { text, PAIS, coordenadas });
  }

  public geocode(text: string): Observable<ICoordenadas> {
    return this.http.post(`/geocode/geocode`, { text });
  }
}
