<mat-form-field class="no-hint">
  <input
    matInput
    #inputDireccion
    placeholder="Dirección"
    [(ngModel)]="direccion"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onDireccionChange($event.option.value)"
  >
    <mat-option *ngFor="let direccion of direcciones" [value]="direccion">
      {{ direccion }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<!-- <div class="input-icon">
  <input matInput [(ngModel)]="coords" placeholder="lat, lng" />
  <button
    mat-icon-button
    (click)="goCentro()"
    [disabled]="!coords"
    color="accent"
  >
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div> -->
