var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// index.ts
import { degreesToRadians, radiansToDegrees } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
function bearing(start, end, options = {}) {
  if (options.final === true) {
    return calculateFinalBearing(start, end);
  }
  const coordinates1 = getCoord(start);
  const coordinates2 = getCoord(end);
  const lon1 = degreesToRadians(coordinates1[0]);
  const lon2 = degreesToRadians(coordinates2[0]);
  const lat1 = degreesToRadians(coordinates1[1]);
  const lat2 = degreesToRadians(coordinates2[1]);
  const a = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const b = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
  return radiansToDegrees(Math.atan2(a, b));
}
__name(bearing, "bearing");
function calculateFinalBearing(start, end) {
  let bear = bearing(end, start);
  bear = (bear + 180) % 360;
  return bear;
}
__name(calculateFinalBearing, "calculateFinalBearing");
var turf_bearing_default = bearing;
export { bearing, turf_bearing_default as default };
