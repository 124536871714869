import { Injectable } from '@angular/core';
import {
  ICreateLocalidad,
  IListado,
  ILocalidad,
  IQueryParam,
  IUpdateLocalidad,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class LocalidadesService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILocalidad>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/localidades`, { params });
  }

  public listarPorId(id: string): Observable<ILocalidad> {
    return this.http.get(`/localidades/${id}`);
  }

  public crear(dato: ICreateLocalidad): Observable<ILocalidad> {
    return this.http.post(`/localidades`, dato);
  }

  public editar(id: string, dato: IUpdateLocalidad): Observable<ILocalidad> {
    return this.http.put(`/localidades/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/localidades/${id}`);
  }
}
