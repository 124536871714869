import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EnvsService } from '../../auxiliares/servicios/envs.service';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { WebSocketService } from '../../auxiliares/servicios/websocket';
import { LoginService } from './login.service';
import { StoreService } from './store.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private loginService: LoginService,
    private envsService: EnvsService,
    private helperService: HelperService,
    private storeService: StoreService,
    private webSocketService: WebSocketService,
    private router: Router,
  ) {}

  public rolesPermitidos(roles: string[]): boolean {
    const usuario = this.storeService.getUsuario();
    const rolesActuales = usuario.roles;
    return !!rolesActuales?.some((rol) => roles.includes(rol));
  }

  public async login(username: string, password: string): Promise<void> {
    const resp = await firstValueFrom(
      this.loginService.login(username, password),
    );
    this.storeService.setLoginInfo(resp);

    const usuario = await firstValueFrom(
      this.loginService.getUsuarioPropio(resp.accessToken!),
    );
    this.storeService.setUsuario(usuario);

    const cliente = await firstValueFrom(
      this.loginService.getClientePropio(resp.accessToken!),
    );
    this.storeService.setCliente(cliente);

    const nombreCliente = this.helperService.nombreClienteToClienteFilename(
      usuario.cliente?.nombre,
    );
    this.envsService.loadCliente(
      nombreCliente,
      undefined,
      usuario.cliente?.imagenes?.icono,
    );
    this.webSocketService.initWs();
    this.router.navigateByUrl('main');
  }

  public async refreshToken(): Promise<void> {
    const refresh_token = this.storeService.getRefreshToken();
    const resp = await firstValueFrom(
      this.loginService.refreshToken(refresh_token),
    );

    this.storeService.setLoginInfo(resp);
  }
}
