import { Injectable } from '@angular/core';
import { ICrearSirena, IListado, IQueryParam, ISirena } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class SirenasService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ISirena>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/sirenas`, { params });
  }

  public listarPorId(id: string): Observable<ISirena> {
    return this.http.get(`/sirenas/${id}`);
  }

  public crear(dato: ICrearSirena): Observable<ISirena> {
    return this.http.post(`/sirenas`, dato);
  }

  public editar(id: string, dato: ICrearSirena): Observable<ISirena> {
    return this.http.put(`/sirenas/${id}`, dato);
  }

  public eliminar(id: string): Observable<ISirena> {
    return this.http.delete(`/sirenas/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve, reject) => {
      let params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/sirenas/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'sirenas.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          }
        );
    });
  }
}
