var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// index.ts
import { geojsonRbush as rbush } from "@turf/geojson-rbush";
import { lineSegment } from "@turf/line-segment";
import { nearestPointOnLine } from "@turf/nearest-point-on-line";
import { booleanPointOnLine } from "@turf/boolean-point-on-line";
import { getCoords } from "@turf/invariant";
import { featureEach, segmentEach } from "@turf/meta";
import { featureCollection, isObject } from "@turf/helpers";
import equal from "deep-equal";
function lineOverlap(line1, line2, options = {}) {
  options = options || {};
  if (!isObject(options)) throw new Error("options is invalid");
  var tolerance = options.tolerance || 0;
  var features = [];
  var tree = rbush();
  const line = lineSegment(line1);
  tree.load(line);
  var overlapSegment;
  let additionalSegments = [];
  segmentEach(line2, function (segment) {
    var doesOverlaps = false;
    if (!segment) {
      return;
    }
    featureEach(tree.search(segment), function (match) {
      if (doesOverlaps === false) {
        var coordsSegment = getCoords(segment).sort();
        var coordsMatch = getCoords(match).sort();
        if (equal(coordsSegment, coordsMatch)) {
          doesOverlaps = true;
          if (overlapSegment) {
            overlapSegment = concatSegment(overlapSegment, segment) || overlapSegment;
          } else overlapSegment = segment;
        } else if (tolerance === 0 ? booleanPointOnLine(coordsSegment[0], match) && booleanPointOnLine(coordsSegment[1], match) : nearestPointOnLine(match, coordsSegment[0]).properties.dist <= tolerance && nearestPointOnLine(match, coordsSegment[1]).properties.dist <= tolerance) {
          doesOverlaps = true;
          if (overlapSegment) {
            overlapSegment = concatSegment(overlapSegment, segment) || overlapSegment;
          } else overlapSegment = segment;
        } else if (tolerance === 0 ? booleanPointOnLine(coordsMatch[0], segment) && booleanPointOnLine(coordsMatch[1], segment) : nearestPointOnLine(segment, coordsMatch[0]).properties.dist <= tolerance && nearestPointOnLine(segment, coordsMatch[1]).properties.dist <= tolerance) {
          if (overlapSegment) {
            const combinedSegment = concatSegment(overlapSegment, match);
            if (combinedSegment) {
              overlapSegment = combinedSegment;
            } else {
              additionalSegments.push(match);
            }
          } else overlapSegment = match;
        }
      }
    });
    if (doesOverlaps === false && overlapSegment) {
      features.push(overlapSegment);
      if (additionalSegments.length) {
        features = features.concat(additionalSegments);
        additionalSegments = [];
      }
      overlapSegment = void 0;
    }
  });
  if (overlapSegment) features.push(overlapSegment);
  return featureCollection(features);
}
__name(lineOverlap, "lineOverlap");
function concatSegment(line, segment) {
  var coords = getCoords(segment);
  var lineCoords = getCoords(line);
  var start = lineCoords[0];
  var end = lineCoords[lineCoords.length - 1];
  var geom = line.geometry.coordinates;
  if (equal(coords[0], start)) geom.unshift(coords[1]);else if (equal(coords[0], end)) geom.push(coords[1]);else if (equal(coords[1], start)) geom.unshift(coords[0]);else if (equal(coords[1], end)) geom.push(coords[0]);else return;
  return line;
}
__name(concatSegment, "concatSegment");
var turf_line_overlap_default = lineOverlap;
export { turf_line_overlap_default as default, lineOverlap };
