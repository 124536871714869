import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ColumnInfo, ColumnSelectService } from '../column-select.service';

@Component({
  selector: 'app-column-select',
  templateUrl: './column-select.component.html',
  styleUrls: ['./column-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnSelectService],
})
export class ColumnSelectComponent implements OnInit {
  @Output() columnsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Input() columns: string[] = [];
  @Input() columnNames: string[] = [];
  @Input() saveName?: string;
  @Input() icon?: string = 'view_column';
  @Input() text?: string = 'Columnas';
  @Input() drag?: boolean = true;

  columnInfo: ColumnInfo[] = [];

  // Mobile query
  public mobileQuery: MediaQueryList;

  constructor(
    private elementRef: ElementRef,
    private columnSelectService: ColumnSelectService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.mobileQuery.addEventListener('change', () =>
      this.changeDetectorRef.detectChanges()
    );
  }

  ngOnInit() {
    this.columnInfo = this.columns.map((currElement, index) => {
      return {
        id: currElement,
        name: this.columnNames[index],
        hidden: false,
      };
    });
    this.columnInfo =
      this.columnSelectService.loadSavedColumnInfo(
        this.columnInfo,
        this.saveName
      ) || this.columnInfo;
    this.emitColumns(false);
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList += 'va-mat-button-no-input';
  }

  // CdkDragDrop<any>
  columnMenuDropped(event: any): void {
    moveItemInArray(
      this.columnInfo,
      event.item.data.columnIndex,
      event.currentIndex
    );
    this.emitColumns(true);
  }

  toggleSelectedColumn(columnId: string) {
    const colFound = this.columnInfo.find((col) => col.id === columnId);
    if (colFound) colFound.hidden = !colFound.hidden;
    this.emitColumns(true);
  }

  private emitColumns(saveColumns: boolean) {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      const columnas = this.columnInfo
        ?.filter((colInfo) => !colInfo.hidden)
        .map((colInfo) => colInfo.id);
      this.columnsChange.emit(columnas);
      if (saveColumns) {
        this.columnSelectService.saveColumnInfo(this.columnInfo, this.saveName);
      }
    });
  }
}
