<ng-container *ngIf="form && controlName">
  <mat-form-field [formGroup]="form">
    <h3 *ngIf="formato"><strong>Formato: </strong> {{ formato }}</h3>

    <p>
      <textarea
        #lineCounter
        id="lineCounter"
        wrap="off"
        readonly
        [ngModel]="lineCounterValue"
        [ngModelOptions]="{ standalone: true }"
      >
      </textarea>
      <textarea
        #textArea
        id="textArea"
        wrap="off"
        matInput
        [formControlName]="controlName"
        required
        (scroll)="onScroll()"
        (input)="onInput()"
      ></textarea>
    </p>
    <mat-hint>
      {{ ejemplo }}
    </mat-hint>
    <mat-error *ngIf="form?.get(controlName)?.hasError('error')">
      {{ getErrorMessage() }}
    </mat-error>
  </mat-form-field>
</ng-container>
