import { Injectable } from '@angular/core';
import {
  ICreateZonaEstacionamiento,
  IListado,
  IQueryParam,
  IUpdateZonaEstacionamiento,
  IZonaEstacionamiento,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { HttpApiService } from 'src/app/auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ZonaEstacionamientosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IZonaEstacionamiento>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/zonaestacionamientos`, { params });
  }

  public listarPorId(id: string): Observable<IZonaEstacionamiento> {
    return this.http.get(`/zonaestacionamientos/${id}`);
  }

  public crear(
    dato: ICreateZonaEstacionamiento,
  ): Observable<IZonaEstacionamiento> {
    return this.http.post(`/zonaestacionamientos`, dato);
  }

  public editar(
    id: string,
    dato: IUpdateZonaEstacionamiento,
  ): Observable<IZonaEstacionamiento> {
    return this.http.put(`/zonaestacionamientos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/zonaestacionamientos/${id}`);
  }
}
