'use strict';

var hasOwn = require('hasown');
var channel = require('side-channel')();
var $TypeError = require('es-errors/type');
var SLOT = {
  assert: function (O, slot) {
    if (!O || typeof O !== 'object' && typeof O !== 'function') {
      throw new $TypeError('`O` is not an object');
    }
    if (typeof slot !== 'string') {
      throw new $TypeError('`slot` must be a string');
    }
    channel.assert(O);
    if (!SLOT.has(O, slot)) {
      throw new $TypeError('`' + slot + '` is not present on `O`');
    }
  },
  get: function (O, slot) {
    if (!O || typeof O !== 'object' && typeof O !== 'function') {
      throw new $TypeError('`O` is not an object');
    }
    if (typeof slot !== 'string') {
      throw new $TypeError('`slot` must be a string');
    }
    var slots = channel.get(O);
    return slots && slots['$' + slot];
  },
  has: function (O, slot) {
    if (!O || typeof O !== 'object' && typeof O !== 'function') {
      throw new $TypeError('`O` is not an object');
    }
    if (typeof slot !== 'string') {
      throw new $TypeError('`slot` must be a string');
    }
    var slots = channel.get(O);
    return !!slots && hasOwn(slots, '$' + slot);
  },
  set: function (O, slot, V) {
    if (!O || typeof O !== 'object' && typeof O !== 'function') {
      throw new $TypeError('`O` is not an object');
    }
    if (typeof slot !== 'string') {
      throw new $TypeError('`slot` must be a string');
    }
    var slots = channel.get(O);
    if (!slots) {
      slots = {};
      channel.set(O, slots);
    }
    slots['$' + slot] = V;
  }
};
if (Object.freeze) {
  Object.freeze(SLOT);
}
module.exports = SLOT;