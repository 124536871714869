import { Injectable } from '@angular/core';
import { ICliente, IToken, IUsuario } from 'modelos/src';
import { ElementosMapa } from '../mapa/mapa-ol/mapa-ol.component';

export interface IElementosMapa {
  zonas: boolean;
  localidades: boolean;
  alertas: boolean;
  sirenas: boolean;
  alcanceSirenas: boolean;
  eventos: boolean;
  puntos: boolean;
  mapaCalor: boolean;
}

const storage = sessionStorage;

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor() {}

  public isLogged() {
    if (storage.getItem('token')) {
      return true;
    }
    return false;
  }

  public setCliente(cliente: ICliente) {
    storage.setItem('cliente', JSON.stringify(cliente));
  }

  public getCliente(): ICliente {
    const cliente = storage.getItem('cliente');
    return cliente ? JSON.parse(cliente) : undefined;
  }

  public getToken() {
    return storage.getItem('token') || '';
  }

  public getRefreshToken(): string {
    return storage.getItem('refreshToken') || '';
  }

  public setUsuario(usuario: IUsuario) {
    storage.setItem('usuario', JSON.stringify(usuario));
  }

  public getUsuario(): IUsuario {
    const user = storage.getItem('usuario');
    return user ? JSON.parse(user) : undefined;
  }

  public static getUsuario(): IUsuario {
    const user = storage.getItem('usuario');
    return user ? JSON.parse(user) : undefined;
  }

  public static getCliente(): ICliente {
    const cliente = storage.getItem('cliente');
    return cliente ? JSON.parse(cliente) : undefined;
  }

  public removeLoginInfo() {
    storage.removeItem('token');
    storage.removeItem('refreshToken');
    storage.removeItem('usuario');
  }

  public setLoginInfo(token: IToken) {
    storage.setItem('token', token.accessToken!);
    storage.setItem('refreshToken', token.refreshToken || '');
  }

  public setElementosMapa(elementos?: IElementosMapa) {
    if (elementos) {
      localStorage.setItem('elementosMapa', JSON.stringify(elementos));
    }
  }

  public getElementosMapa(): IElementosMapa {
    const elementos = localStorage.getItem('elementosMapa');
    const obj = elementos ? JSON.parse(elementos) : {};

    return (
      obj || {
        zonas: true,
        alertas: true,
        sirenas: true,
        alcanceSirenas: true,
        eventos: true,
        puntos: true,
        mapaCalor: false,
      }
    );
  }

  public static setElementos(elementos: ElementosMapa) {
    localStorage.setItem('elementos', JSON.stringify(elementos));
  }

  public static getElementos(): ElementosMapa {
    const elementos = localStorage.getItem('elementos');
    const obj = elementos ? JSON.parse(elementos) : {};

    return (
      obj || {
        pin: true,
        zonas: true,
        localidades: true,
      }
    );
  }
}
