'use strict';

/** @const */
var $Map = typeof Map === 'function' && Map.prototype ? Map : null;
var $Set = typeof Set === 'function' && Set.prototype ? Set : null;
var exported;
if (!$Map) {
  /** @type {import('.')} */
  // eslint-disable-next-line no-unused-vars
  exported = function isMap(x) {
    // `Map` is not present in this environment.
    return false;
  };
}
var $mapHas = $Map ? Map.prototype.has : null;
var $setHas = $Set ? Set.prototype.has : null;
if (!exported && !$mapHas) {
  /** @type {import('.')} */
  // eslint-disable-next-line no-unused-vars
  exported = function isMap(x) {
    // `Map` does not have a `has` method
    return false;
  };
}

/** @type {import('.')} */
module.exports = exported || function isMap(x) {
  if (!x || typeof x !== 'object') {
    return false;
  }
  try {
    $mapHas.call(x);
    if ($setHas) {
      try {
        $setHas.call(x);
      } catch (e) {
        return true;
      }
    }
    // @ts-expect-error TS can't figure out that $Map is always truthy here
    return x instanceof $Map; // core-js workaround, pre-v2.5.0
  } catch (e) {}
  return false;
};