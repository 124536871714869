import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ICoordenadas } from 'modelos/src';
import { Feature, Map, View } from 'ol';
import { OpenLayersService } from '../servicios/open-layers.service';
import { Geometry, Point } from 'ol/geom';

@Component({
  selector: 'app-ubicar-ol',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ubicar-ol.component.html',
  styleUrl: './ubicar-ol.component.scss',
})
export class UbicarOlComponent implements OnInit {
  @Input() c: ICoordenadas;
  @Input() direccion?: string;

  private map?: Map;
  private pinLayer = OpenLayersService.pinsVectorlayer();

  constructor() {}

  private async initMap() {
    const center = OpenLayersService.coordenadaToCoordinate(this.c);
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [OpenLayersService.mapTile(), this.pinLayer],
      view: new View({
        center,
        zoom: 12,
      }),
    });
    this.addPin();
  }

  private addPin() {
    const c = OpenLayersService.coordenadaToCoordinate(this.c);
    const source = this.pinLayer.getSource();
    if (!source) return;
    const feature: Feature<Geometry> = new Feature({
      geometry: new Point(c),
    });
    source.addFeature(feature);
  }

  async ngOnInit() {
    await this.initMap();
  }
}
