/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import {
  ISocketMessage,
  IQueryParam,
  IAlerta,
  IListado,
  IUsuario,
  IZona,
  IEvento,
  ICentroMonitoreo,
  IVecino,
  IDetalleAlerta,
  ICategoria,
  IBoton,
  ISirena,
  IEventoSirena,
  IControl,
  IBaneoVecino,
  ICategoriaVecino,
  IMensaje,
  IEstadoSirena,
  IAlertaMensaje,
  IAuditoria,
  IArchivoVecino,
  IApiKey,
  IAlertaMedia,
  ILocalidad,
  IBarrio,
  INotificacion,
  IPunto,
  IConfigVecino,
  IReclamo,
  IEstadoReclamo,
  IGrupo,
  IConfigTurno,
  ITurno,
  IZonaEstacionamiento,
  IDerivacion,
  IAcceso,
  IVehiculo,
  IVisitante,
  ITopAlertasVecino,
  ITopEventosSirenaVecino,
  IPortico,
  ILecturaPortico,
  ITokenVecino,
  IEventoExterno,
} from 'modelos/src';
import { UsuariosService } from '../../entidades/usuarios/usuarios.service';
import { ZonasService } from '../../entidades/zonas/zonas.service';
import { VecinosService } from '../../entidades/vecinos/vecinos.service';
import { CategoriasService } from '../../entidades/vecinos/categorias.service';
import { WebSocketService } from './websocket';
import { AlertasService } from '../../entidades/alertas/alertas.service';
import { CentrosMonitoreoService } from '../../entidades/centros-monitoreo/centros-monitoreo.service';
import { BotonesService } from '../../entidades/usuarios/botones.service';
import { SirenasService } from '../../entidades/sirenas/sirenas.service';
import { ControlesService } from '../../entidades/vecinos/controles.service';
import { BaneosService } from '../../entidades/vecinos/baneos.service';
import { CategoriasVecinosService } from '../../entidades/vecinos/categorias-vecinos.service';
import { EventosSirenasService } from '../../entidades/sirenas/eventos-sirena.service';
import { MensajesService } from '../../entidades/mensajes/mensajes.service';
import { EstadosSirenasService } from '../../entidades/sirenas/estados-sirena.service';
import { AuditoriasService } from '../../entidades/auditorias/auditorias.service';
import { ArchivosVecinoService } from 'src/app/entidades/vecinos/archivos-vecino.service';
import { ApikeyService } from 'src/app/entidades/apikey/apikey.service';
import { LocalidadesService } from 'src/app/entidades/localidades/localidades.service';
import { BarriosService } from 'src/app/entidades/barrios/barrios.service';
import { NotificacionesService } from '../../entidades/mensajes-push/mensajes.service';
import { PuntosService } from 'src/app/entidades/puntos/puntos.service';
import { ConfigVecinosService } from 'src/app/entidades/vecinos/config-vecinos.service';
import { ReclamosService } from 'src/app/entidades/reclamos/reclamos.service';
import { GruposService } from 'src/app/entidades/grupos/grupos.service';
import { SucesosService } from 'src/app/entidades/sucesos/sucesos.service';
import { ConfigTurnosService } from 'src/app/entidades/config-turnos/config-turnos.service';
import { TurnosService } from 'src/app/entidades/turnos/turnos.service';
import { ZonaEstacionamientosService } from 'src/app/entidades/zona-estacionamientos/zona-estacionamientos.service';
import { DerivacionsService } from '../../entidades/derivacion/service';
import { HelperService } from './helper.service';
import { AccesosService } from '../../entidades/accesos/accesos.service';
import { VehiculosService } from '../../entidades/accesos/vehiculos.service';
import { VisitantesService } from '../../entidades/accesos/visitantes.service';
import { PorticosService } from 'src/app/entidades/porticos/porticos.service';
import { LecturaPorticoService } from 'src/app/entidades/porticos/lectura-portico.service';
import { TokenVecinosService } from 'src/app/entidades/auditorias/token-vecino.service';
import { EventosExternosService } from 'src/app/entidades/eventos-externos/eventos-externos.service';

type Tipo =
  | IDetalleAlerta
  | IListado<IAlerta>
  | IListado<ISirena>
  | IListado<IUsuario>
  | IListado<IZona>
  | IListado<ICentroMonitoreo>
  | IVecino
  | IListado<IVecino>
  | IListado<ICategoria>
  | IListado<IBoton>
  | IListado<IEventoSirena>
  | IListado<IEstadoSirena>
  | IControl
  | IListado<IBaneoVecino>
  | IListado<ICategoriaVecino>
  | IListado<IMensaje>
  | IListado<IControl>
  | IEvento
  | IListado<IEvento>
  | IListado<IAlertaMensaje>
  | IAuditoria
  | IListado<IAuditoria>
  | IArchivoVecino
  | IListado<IArchivoVecino>
  | IApiKey
  | IListado<IApiKey>
  | ILocalidad
  | IListado<ILocalidad>
  | IBarrio
  | IListado<IBarrio>
  | INotificacion
  | IListado<INotificacion>
  | IPunto
  | IListado<IPunto>
  | IConfigVecino
  | IListado<IConfigVecino>
  | IReclamo
  | IListado<IReclamo>
  | IListado<IEstadoReclamo>
  | IGrupo
  | IListado<IGrupo>
  | IConfigTurno
  | IListado<IConfigTurno>
  | ITurno
  | IListado<ITurno>
  | IZonaEstacionamiento
  | IListado<IZonaEstacionamiento>
  | IDerivacion
  | IListado<IDerivacion>
  | IAcceso
  | IListado<IAcceso>
  | IVehiculo
  | IListado<IVehiculo>
  | IVisitante
  | IListado<IVisitante>
  | ITopAlertasVecino[]
  | ITopEventosSirenaVecino[]
  | IPortico
  | IListado<IPortico>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  lastRequest: number;
  cache?: Tipo;

  constructor() {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
    this.lastRequest = 0;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryParam) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  alerta: IRequestId;
  alertas: IRequestQuery;
  sirenas: IRequestQuery;
  usuarios: IRequestQuery;
  zonas: IRequestQuery;
  centrosMonitoreo: IRequestQuery;
  vecino: IRequestId;
  vecinos: IRequestQuery;
  categorias: IRequestQuery;
  botones: IRequestQuery;
  eventosSirena: IRequestQuery;
  estadosSirena: IRequestQuery;
  controlVecino: IRequestId;
  baneos: IRequestQuery;
  categoriasVecinos: IRequestQuery;
  mensajes: IRequestQuery;
  controles: IRequestQuery;
  evento: IRequestId;
  eventos: IRequestQuery;
  alertaMensajes: IRequestQuery;
  auditoria: IRequestId;
  auditorias: IRequestQuery;
  archivoVecino: IRequestId;
  archivosVecino: IRequestQuery;
  apikey: IRequestId;
  apikeys: IRequestQuery;
  localidad: IRequestId;
  localidades: IRequestQuery;
  barrio: IRequestId;
  barrios: IRequestQuery;
  // notificacion: IRequestId;
  notificaciones: IRequestQuery;
  punto: IRequestId;
  puntos: IRequestQuery;
  configvecino: IRequestId;
  configvecinos: IRequestQuery;
  reclamo: IRequestId;
  reclamos: IRequestQuery;
  estadoReclamos: IRequestId;
  grupo: IRequestId;
  grupos: IRequestQuery;
  configturno: IRequestId;
  configturnos: IRequestQuery;
  turno: IRequestId;
  turnos: IRequestQuery;
  zonaestacionamiento: IRequestId;
  zonaestacionamientos: IRequestQuery;
  derivacion: IRequestId;
  derivacions: IRequestQuery;
  acceso: IRequestId;
  accesos: IRequestQuery;
  accesosAgrupados: IRequestQuery;
  vehiculo: IRequestId;
  vehiculos: IRequestQuery;
  visitante: IRequestId;
  visitantes: IRequestQuery;
  topAlertasVecino: IRequestQuery;
  topEventosSirenasVecino: IRequestQuery;
  portico: IRequestId;
  porticos: IRequestQuery;
  lecturaPortico: IRequestId;
  lecturasPorticos: IRequestQuery;
  tokenVecino: IRequestId;
  tokensVecinos: IRequestQuery;
  eventoExterno: IRequestId;
  eventosExternos: IRequestQuery;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  // Mensajes que llegan desde websocket service
  private socketMsg$ = new Subject<ISocketMessage>();
  //
  private entidades: IEntidades = this.getInitCache();

  constructor(
    private webSocketService: WebSocketService,
    private helper: HelperService,
    //
    private alertasService: AlertasService,
    private usuariosService: UsuariosService,
    private zonasService: ZonasService,
    private centrosMonitoreoService: CentrosMonitoreoService,
    private vecinosService: VecinosService,
    private categoriasService: CategoriasService,
    private botonesService: BotonesService,
    private sirenasService: SirenasService,
    private eventosSirenasService: EventosSirenasService,
    private baneosService: BaneosService,
    private categoriasVecinosService: CategoriasVecinosService,
    private mensajesService: MensajesService,
    private estadosSirenasService: EstadosSirenasService,
    private controlesService: ControlesService,
    private sucesosService: SucesosService,
    private auditoriasService: AuditoriasService,
    private archivosVecino: ArchivosVecinoService,
    private apikeysService: ApikeyService,
    private localidadesService: LocalidadesService,
    private barriosService: BarriosService,
    private notificacionesService: NotificacionesService,
    private puntosService: PuntosService,
    private configVecinosService: ConfigVecinosService,
    private reclamosService: ReclamosService,
    private gruposService: GruposService,
    private configTurnosService: ConfigTurnosService,
    private turnosService: TurnosService,
    private zonaEstacionamientoService: ZonaEstacionamientosService,
    private derivacionsService: DerivacionsService,
    private accesosService: AccesosService,
    private vehiculosService: VehiculosService,
    private visitantesService: VisitantesService,
    private porticosService: PorticosService,
    private lecturasPorticosService: LecturaPorticoService,
    private tokensVecinosService: TokenVecinosService,
    private eventosExternosService: EventosExternosService,
  ) {
    this.subscribeWsUpdates();
  }

  // Subscribe

  public subscribe<Tipo>(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue();
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(entidad, query, (ent as IRequestId).fn);
      } else {
        await this.listarQuery(entidad, query, (ent as IRequestQuery).fn);
      }
    }
  }

  // Listados Entidades
  private async listarAlerta(id: string): Promise<IDetalleAlerta> {
    const response = await firstValueFrom(this.alertasService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarAlertas(query: IQueryParam): Promise<IListado<IAlerta>> {
    try {
      const response = await firstValueFrom(this.alertasService.listar(query));
      return JSON.parse(JSON.stringify(response));
    } catch (error) {
      console.error(error);
      this.helper.notifError('Error al listar alertas');
      return { datos: [], totalCount: 0 };
    }
  }

  private async topAlertasVecino(
    query: IQueryParam,
  ): Promise<ITopAlertasVecino[]> {
    try {
      const response = await firstValueFrom(
        this.alertasService.getTopByVecino(query),
      );
      return JSON.parse(JSON.stringify(response));
    } catch (error) {
      console.error(error);
      this.helper.notifError('Error al listar top alertas vecino');
      return [];
    }
  }

  private async topEventosSirenasVecino(
    query: IQueryParam,
  ): Promise<ITopEventosSirenaVecino[]> {
    const response = await firstValueFrom(
      this.eventosSirenasService.getTopByVecino(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarSirenas(query: IQueryParam): Promise<IListado<ISirena>> {
    const response = await firstValueFrom(this.sirenasService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuarios(
    query: IQueryParam,
  ): Promise<IListado<IUsuario>> {
    const response = await firstValueFrom(this.usuariosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarZonas(query: IQueryParam): Promise<IListado<IZona>> {
    const response = await firstValueFrom(this.zonasService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarCentrosMonitoreo(
    query: IQueryParam,
  ): Promise<IListado<ICentroMonitoreo>> {
    const response = await firstValueFrom(
      this.centrosMonitoreoService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVecinos(query: IQueryParam): Promise<IListado<IVecino>> {
    const response = await firstValueFrom(this.vecinosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarVecino(id: string): Promise<IVecino> {
    const response = await firstValueFrom(this.vecinosService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarCategorias(
    query: IQueryParam,
  ): Promise<IListado<ICategoria>> {
    const response = await firstValueFrom(this.categoriasService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarBotones(query: IQueryParam): Promise<IListado<IBoton>> {
    const response = await firstValueFrom(this.botonesService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventosSirenas(
    query: IQueryParam,
  ): Promise<IListado<IEventoSirena>> {
    const response = await firstValueFrom(
      this.eventosSirenasService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarEstadosSirenas(
    query: IQueryParam,
  ): Promise<IListado<IEstadoSirena>> {
    const response = await firstValueFrom(
      this.estadosSirenasService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarControlVecino(id: string): Promise<IControl> {
    const response = await firstValueFrom(
      this.controlesService.listarPorIdVecino(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarBaneos(
    query: IQueryParam,
  ): Promise<IListado<IBaneoVecino>> {
    const response = await firstValueFrom(this.baneosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarCategiriasVecinos(
    query: IQueryParam,
  ): Promise<IListado<ICategoriaVecino>> {
    const response = await firstValueFrom(
      this.categoriasVecinosService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarMensajes(
    query: IQueryParam,
  ): Promise<IListado<IMensaje>> {
    const response = await firstValueFrom(this.mensajesService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarControles(
    query: IQueryParam,
  ): Promise<IListado<IControl>> {
    const response = await firstValueFrom(this.controlesService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarEvento(id: string): Promise<IEvento> {
    const response = await firstValueFrom(this.sucesosService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventos(query: IQueryParam): Promise<IListado<IEvento>> {
    const response = await firstValueFrom(this.sucesosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarAlertaMensajes(
    query: IQueryParam,
  ): Promise<IListado<IEvento>> {
    const response = await firstValueFrom(
      this.alertasService.listarMensajesAlerta(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarAuditoria(id: string): Promise<IAuditoria> {
    const response = await firstValueFrom(
      this.auditoriasService.listarPorId(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarAuditorias(
    query: IQueryParam,
  ): Promise<IListado<IAuditoria>> {
    const response = await firstValueFrom(this.auditoriasService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarArchivoVecino(id: string): Promise<IArchivoVecino> {
    const response = await firstValueFrom(this.archivosVecino.getByID(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarArchivosVecino(
    query: IQueryParam,
  ): Promise<IListado<IArchivoVecino>> {
    const response = await firstValueFrom(
      this.archivosVecino.getFiltered(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarApikey(id: string): Promise<IApiKey> {
    const response = await firstValueFrom(this.apikeysService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarApikeys(query: IQueryParam): Promise<IListado<IApiKey>> {
    const response = await firstValueFrom(this.apikeysService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarLocalidad(id: string): Promise<ILocalidad> {
    const response = await firstValueFrom(
      this.localidadesService.listarPorId(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarLocalidades(
    query: IQueryParam,
  ): Promise<IListado<ILocalidad>> {
    const response = await firstValueFrom(
      this.localidadesService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarBarrio(id: string): Promise<IBarrio> {
    const response = await firstValueFrom(this.barriosService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarBarrios(query: IQueryParam): Promise<IListado<IBarrio>> {
    const response = await firstValueFrom(this.barriosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarNotificaciones(
    query: IQueryParam,
  ): Promise<IListado<INotificacion>> {
    const response = await firstValueFrom(
      this.notificacionesService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarPunto(id: string): Promise<IPunto> {
    const response = await firstValueFrom(this.puntosService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarPuntos(query: IQueryParam): Promise<IListado<IPunto>> {
    const response = await firstValueFrom(this.puntosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigVecino(id: string): Promise<IConfigVecino> {
    const response = await firstValueFrom(
      this.configVecinosService.listarPorId(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigVecinos(
    query: IQueryParam,
  ): Promise<IListado<IConfigVecino>> {
    const response = await firstValueFrom(
      this.configVecinosService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarReclamo(id: string): Promise<IReclamo> {
    const response = await firstValueFrom(this.reclamosService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarReclamos(
    query: IQueryParam,
  ): Promise<IListado<IReclamo>> {
    const response = await firstValueFrom(this.reclamosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarEstadoReclamos(
    id: string,
  ): Promise<IListado<IEstadoReclamo>> {
    const response = await firstValueFrom(
      this.reclamosService.listarEstadosPorId(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupo(id: string): Promise<IGrupo> {
    const response = await firstValueFrom(this.gruposService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupos(query: IQueryParam): Promise<IListado<IGrupo>> {
    const response = await firstValueFrom(this.gruposService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigTurno(id: string): Promise<IConfigTurno> {
    const response = await firstValueFrom(
      this.configTurnosService.listarPorId(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigTurnos(
    query: IQueryParam,
  ): Promise<IListado<IConfigTurno>> {
    const response = await firstValueFrom(
      this.configTurnosService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarTurno(id: string): Promise<ITurno> {
    const response = await firstValueFrom(this.turnosService.listarPorId(id));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarTurnos(query: IQueryParam): Promise<IListado<ITurno>> {
    const response = await firstValueFrom(this.turnosService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarZonaEstacionamiento(
    id: string,
  ): Promise<IZonaEstacionamiento> {
    const response = await firstValueFrom(
      this.zonaEstacionamientoService.listarPorId(id),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarZonaEstacionamientos(
    query: IQueryParam,
  ): Promise<IListado<IZonaEstacionamiento>> {
    const response = await firstValueFrom(
      this.zonaEstacionamientoService.listar(query),
    );

    return JSON.parse(JSON.stringify(response));
  }

  private async listarDerivacion(id: string): Promise<IDerivacion> {
    const response = await firstValueFrom(
      this.derivacionsService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDerivacions(
    query: IQueryParam,
  ): Promise<IListado<IDerivacion>> {
    const response = await firstValueFrom(
      this.derivacionsService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAcceso(id: string): Promise<IAcceso> {
    const response = await firstValueFrom(this.accesosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAccesos(query: IQueryParam): Promise<IListado<IAcceso>> {
    const response = await firstValueFrom(this.accesosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAccesosAgrupados(
    query: IQueryParam,
  ): Promise<IListado<IAcceso>> {
    const response = await firstValueFrom(
      this.accesosService.listarAgrupados(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVehiculo(id: string): Promise<IVehiculo> {
    const response = await firstValueFrom(
      this.vehiculosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVehiculos(
    query: IQueryParam,
  ): Promise<IListado<IVehiculo>> {
    const response = await firstValueFrom(this.vehiculosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVisitante(id: string): Promise<IVisitante> {
    const response = await firstValueFrom(
      this.visitantesService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVisitantes(
    query: IQueryParam,
  ): Promise<IListado<IVisitante>> {
    const response = await firstValueFrom(this.visitantesService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarPortico(id: string): Promise<IPortico> {
    const response = await firstValueFrom(this.porticosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarPorticos(
    query: IQueryParam,
  ): Promise<IListado<IPortico>> {
    const response = await firstValueFrom(this.porticosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTokenVecino(id: string): Promise<ITokenVecino> {
    const response = await firstValueFrom(
      this.tokensVecinosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTokensVecinos(
    query: IQueryParam,
  ): Promise<IListado<ITokenVecino>> {
    const response = await firstValueFrom(
      this.tokensVecinosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventoExterno(id: string): Promise<IEventoExterno> {
    const response = await firstValueFrom(
      this.eventosExternosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventosExternos(
    query: IQueryParam,
  ): Promise<IListado<IEventoExterno>> {
    const response = await firstValueFrom(
      this.eventosExternosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLecturaPortico(id: string): Promise<ILecturaPortico> {
    const response = await firstValueFrom(
      this.lecturasPorticosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLecturaPorticos(
    query: IQueryParam,
  ): Promise<IListado<IPortico>> {
    const response = await firstValueFrom(
      this.lecturasPorticosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  // Listados para subscribe directo
  public listarVecinos$(queryParams?: IQueryParam): Observable<IVecino[]> {
    return new Observable((observer) => {
      this.vecinosService.listar(queryParams).subscribe((vecinos) => {
        observer.next(vecinos.datos);
      });
    });
  }

  // Listados para subscribe directo
  public listarConfigVecinos$(
    queryParams?: IQueryParam,
  ): Observable<IConfigVecino[]> {
    return new Observable((observer) => {
      this.configVecinosService.listar(queryParams).subscribe((vecinos) => {
        observer.next(vecinos.datos);
      });
    });
  }

  // Listados para subscribe directo
  public listarVisitantes$(
    queryParams?: IQueryParam,
  ): Observable<IVisitante[]> {
    return new Observable((observer) => {
      this.visitantesService.listar(queryParams).subscribe((resp) => {
        observer.next(resp.datos);
      });
    });
  }

  // Listados para subscribe directo
  public listarVehiculos$(queryParams?: IQueryParam): Observable<IVehiculo[]> {
    return new Observable((observer) => {
      this.vehiculosService.listar(queryParams).subscribe((resp) => {
        observer.next(resp.datos);
      });
    });
  }

  // Listados para subscribe directo
  public listarUsuarios$(queryParams?: IQueryParam): Observable<IUsuario[]> {
    return new Observable((observer) => {
      this.usuariosService.listar(queryParams).subscribe((resp) => {
        observer.next(resp.datos);
      });
    });
  }

  // Listados para subscribe directo
  public listarCategorias$(
    queryParams?: IQueryParam,
  ): Observable<ICategoria[]> {
    return new Observable((observer) => {
      this.categoriasService.listar(queryParams).subscribe((resp) => {
        observer.next(resp.datos);
      });
    });
  }

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      alerta: { fn: this.listarAlerta.bind(this), keys: {} },
      alertas: { fn: this.listarAlertas.bind(this), keys: {} },
      sirenas: { fn: this.listarSirenas.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      zonas: { fn: this.listarZonas.bind(this), keys: {} },
      centrosMonitoreo: {
        fn: this.listarCentrosMonitoreo.bind(this),
        keys: {},
      },
      vecino: { fn: this.listarVecino.bind(this), keys: {} },
      vecinos: { fn: this.listarVecinos.bind(this), keys: {} },
      categorias: { fn: this.listarCategorias.bind(this), keys: {} },
      botones: { fn: this.listarBotones.bind(this), keys: {} },
      eventosSirena: { fn: this.listarEventosSirenas.bind(this), keys: {} },
      estadosSirena: { fn: this.listarEstadosSirenas.bind(this), keys: {} },
      controlVecino: { fn: this.listarControlVecino.bind(this), keys: {} },
      baneos: { fn: this.listarBaneos.bind(this), keys: {} },
      categoriasVecinos: {
        fn: this.listarCategiriasVecinos.bind(this),
        keys: {},
      },
      mensajes: { fn: this.listarMensajes.bind(this), keys: {} },
      controles: { fn: this.listarControles.bind(this), keys: {} },
      evento: { fn: this.listarEvento.bind(this), keys: {} },
      eventos: { fn: this.listarEventos.bind(this), keys: {} },
      alertaMensajes: { fn: this.listarAlertaMensajes.bind(this), keys: {} },
      auditoria: { fn: this.listarAuditoria.bind(this), keys: {} },
      auditorias: { fn: this.listarAuditorias.bind(this), keys: {} },
      archivoVecino: { fn: this.listarArchivoVecino.bind(this), keys: {} },
      archivosVecino: { fn: this.listarArchivosVecino.bind(this), keys: {} },
      apikey: { fn: this.listarApikey.bind(this), keys: {} },
      apikeys: { fn: this.listarApikeys.bind(this), keys: {} },
      localidad: { fn: this.listarLocalidad.bind(this), keys: {} },
      localidades: { fn: this.listarLocalidades.bind(this), keys: {} },
      barrio: { fn: this.listarBarrio.bind(this), keys: {} },
      barrios: { fn: this.listarBarrios.bind(this), keys: {} },
      notificaciones: { fn: this.listarNotificaciones.bind(this), keys: {} },
      punto: { fn: this.listarPunto.bind(this), keys: {} },
      puntos: { fn: this.listarPuntos.bind(this), keys: {} },
      configvecino: { fn: this.listarConfigVecino.bind(this), keys: {} },
      configvecinos: { fn: this.listarConfigVecinos.bind(this), keys: {} },
      reclamo: { fn: this.listarReclamo.bind(this), keys: {} },
      reclamos: { fn: this.listarReclamos.bind(this), keys: {} },
      estadoReclamos: { fn: this.listarEstadoReclamos.bind(this), keys: {} },
      grupo: { fn: this.listarGrupo.bind(this), keys: {} },
      grupos: { fn: this.listarGrupos.bind(this), keys: {} },
      configturno: { fn: this.listarConfigTurno.bind(this), keys: {} },
      configturnos: { fn: this.listarConfigTurnos.bind(this), keys: {} },
      turno: { fn: this.listarTurno.bind(this), keys: {} },
      turnos: { fn: this.listarTurnos.bind(this), keys: {} },
      zonaestacionamiento: {
        fn: this.listarZonaEstacionamiento.bind(this),
        keys: {},
      },
      zonaestacionamientos: {
        fn: this.listarZonaEstacionamientos.bind(this),
        keys: {},
      },
      derivacion: { fn: this.listarDerivacion.bind(this), keys: {} },
      derivacions: { fn: this.listarDerivacions.bind(this), keys: {} },
      acceso: { fn: this.listarAcceso.bind(this), keys: {} },
      accesos: { fn: this.listarAccesos.bind(this), keys: {} },
      accesosAgrupados: {
        fn: this.listarAccesosAgrupados.bind(this),
        keys: {},
      },
      vehiculo: { fn: this.listarVehiculo.bind(this), keys: {} },
      vehiculos: { fn: this.listarVehiculos.bind(this), keys: {} },
      visitante: { fn: this.listarVisitante.bind(this), keys: {} },
      visitantes: { fn: this.listarVisitantes.bind(this), keys: {} },
      topAlertasVecino: { fn: this.topAlertasVecino.bind(this), keys: {} },
      topEventosSirenasVecino: {
        fn: this.topEventosSirenasVecino.bind(this),
        keys: {},
      },
      portico: { fn: this.listarPortico.bind(this), keys: {} },
      porticos: { fn: this.listarPorticos.bind(this), keys: {} },
      lecturaPortico: { fn: this.listarLecturaPortico.bind(this), keys: {} },
      lecturasPorticos: { fn: this.listarLecturaPorticos.bind(this), keys: {} },
      tokenVecino: { fn: this.listarTokenVecino.bind(this), keys: {} },
      tokensVecinos: { fn: this.listarTokensVecinos.bind(this), keys: {} },
      eventoExterno: { fn: this.listarEventoExterno.bind(this), keys: {} },
      eventosExternos: { fn: this.listarEventosExternos.bind(this), keys: {} },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
  }

  private waitXSeconds(seconds: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
  }

  // Actualizar Entidades
  private async actualizarQuery(entidad: keyof IEntidades): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    for (const key in ent.keys) {
      if (ent.keys[key].requests === 0) {
        if (Object.prototype.hasOwnProperty.call(ent.keys, key)) {
          ent.keys[key].cache = undefined;
          const query = JSON.parse(key);
          if (ent.keys[key].subscribe.observers.length) {
            ent.keys[key].requests++;
            while (ent.keys[key].requests) {
              ent.keys[key].cache = undefined;
              const lastRequest = ent.keys[key].lastRequest;
              const now = Date.now();
              if (lastRequest && lastRequest + 5000 > now) {
                await this.waitXSeconds(5);
              }
              await this.listarQuery(entidad, query, ent.fn);
              ent.keys[key].lastRequest = Date.now();
              ent.keys[key].requests--;
            }
          }
        }
      } else if (ent.keys[key].requests < 2) {
        ent.keys[key].requests++;
      }
    }
  }

  private async actualizarId(
    entidad: keyof IEntidades,
    id?: string,
  ): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          if (ent.keys[id].subscribe.observers.length) {
            ent.keys[id].requests++;
            while (ent.keys[id].requests) {
              ent.keys[id].cache = undefined;
              const lastRequest = ent.keys[id].lastRequest;
              const now = Date.now();
              if (lastRequest && lastRequest + 5000 > now) {
                await this.waitXSeconds(5);
              }
              await this.listarId(entidad, id, ent.fn);
              ent.keys[id].lastRequest = Date.now();
              ent.keys[id].requests--;
            }
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales

  private async listarQuery(
    entidad: keyof IEntidades,
    query: IQueryParam,
    fn: (query: IQueryParam) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (!ent.keys[key]?.cache) {
      const response = await fn(query);
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
      ent.keys[key].subscribe.next(response);
    } else {
      ent.keys[key].subscribe.next(ent.keys[key]?.cache!);
    }
  }

  private async listarId(
    entidad: keyof IEntidades,
    id: string,
    fn: (id: string) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      const response = await fn(id);
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
      ent.keys[id].subscribe.next(response);
    } else {
      ent.keys[id].subscribe.next(ent.keys[id]?.cache!);
    }
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('alertas')) {
      this.actualizarQuery('alertas');
      this.actualizarId('alerta', message.body?._id);
      if (message.method?.toLowerCase() === 'post') {
        this.actualizarQuery('topAlertasVecino');
      }
    }
    if (message.paths?.includes('alertasmedia')) {
      const data = message.body as IAlertaMedia;
      this.actualizarId('alerta', data.idAlerta);
      // this.actualizarId('alertasMedia', data._id);
      // this.actualizarQuery('alertasMedia');
    }
    if (message.paths?.includes('alertamensajes')) {
      this.actualizarQuery('alertaMensajes');
    }
    if (message.paths?.includes('sirenas')) {
      this.actualizarQuery('sirenas');
      this.actualizarQuery('estadosSirena');
      this.actualizarQuery('eventosSirena');
    }
    if (message.paths?.includes('eventossirenas')) {
      if (message.method?.toLowerCase() === 'post') {
        this.actualizarQuery('topEventosSirenasVecino');
      }
    }
    if (message.paths?.includes('usuarios')) {
      this.actualizarQuery('usuarios');
    }
    if (message.paths?.includes('zonas')) {
      this.actualizarQuery('zonas');
    }
    if (message.paths?.includes('centrosmonitoreo')) {
      this.actualizarQuery('centrosMonitoreo');
    }
    if (message.paths?.includes('vecinos')) {
      this.actualizarQuery('vecinos');
      this.actualizarId('vecino', message.body?._id);
      this.actualizarQuery('categoriasVecinos');
    }
    if (message.paths?.includes('categorias')) {
      this.actualizarQuery('categorias');
    }
    if (message.paths?.includes('botones')) {
      this.actualizarQuery('botones');
    }
    if (message.paths?.includes('controles')) {
      this.actualizarQuery('controles');
      this.actualizarId('controlVecino', message.body?.idVecino);
    }
    if (message.paths?.includes('baneosvecinos')) {
      this.actualizarQuery('baneos');
    }
    if (message.paths?.includes('categoriasvecinos')) {
      this.actualizarQuery('categoriasVecinos');
    }
    if (message.paths?.includes('mensajes')) {
      this.actualizarQuery('mensajes');
    }
    if (message.paths?.includes('eventos')) {
      this.actualizarId('evento', message.body?._id);
      this.actualizarQuery('eventos');
    }
    if (message.paths?.includes('apikeys')) {
      this.actualizarId('apikey', message.body?._id);
      this.actualizarQuery('apikeys');
    }
    if (message.paths?.includes('localidades')) {
      this.actualizarId('localidad', message.body?._id);
      this.actualizarQuery('localidades');
    }
    if (message.paths?.includes('barrios')) {
      this.actualizarId('barrio', message.body?._id);
      this.actualizarQuery('barrios');
    }
    if (message.paths?.includes('notificaciones')) {
      // this.actualizarId('notificacion', message.body?._id);
      this.actualizarQuery('notificaciones');
    }

    if (message.paths?.includes('puntos')) {
      this.actualizarId('punto', message.body?._id);
      this.actualizarQuery('puntos');
    }

    if (message.paths?.includes('configvecinos')) {
      this.actualizarId('configvecino', message.body?._id);
      this.actualizarQuery('configvecinos');
      this.actualizarQuery('vecinos');
    }

    if (message.paths?.includes('reclamos')) {
      this.actualizarId('reclamo', message.body?._id);
      this.actualizarQuery('reclamos');
      this.actualizarQuery('reclamos');
    }

    if (message.paths?.includes('estadoReclamos')) {
      this.actualizarId('estadoReclamos', message.body?.idReclamo);
    }

    if (message.paths?.includes('grupos')) {
      this.actualizarId('grupo', message.body?._id);
      this.actualizarQuery('grupos');
    }

    if (message.paths?.includes('configturnos')) {
      this.actualizarId('configturno', message.body?._id);
      this.actualizarQuery('configturnos');
    }

    if (message.paths?.includes('turnos')) {
      this.actualizarId('turno', message.body?._id);
      this.actualizarQuery('turnos');
    }

    if (message.paths?.includes('zonaestacionamientos')) {
      this.actualizarId('zonaestacionamiento', message.body?._id);
      this.actualizarQuery('zonaestacionamientos');
    }

    if (message.paths?.includes('archivosvecinos')) {
      this.actualizarId('archivoVecino', message.body?._id);
      this.actualizarQuery('archivosVecino');
    }

    if (message.paths?.includes('accesos')) {
      this.actualizarId('acceso', message.body?._id);
      this.actualizarQuery('accesos');
      this.actualizarQuery('accesosAgrupados');
    }

    if (message.paths?.includes('vehiculos')) {
      this.actualizarId('vehiculo', message.body?._id);
      this.actualizarQuery('vehiculos');
    }

    if (message.paths?.includes('visitantes')) {
      this.actualizarId('visitante', message.body?._id);
      this.actualizarQuery('visitantes');
    }

    if (message.paths?.includes('porticos')) {
      this.actualizarId('portico', message.body?._id);
      this.actualizarQuery('porticos');
    }

    if (message.paths?.includes('eventosExternos')) {
      this.actualizarId('eventosExternos', message.body?._id);
      this.actualizarQuery('eventosExternos');
    }
    //
    this.socketMsg$.next(message);
  }
  // Reenvio de mensaje para los componentes
  public getMessage(): Observable<ISocketMessage> {
    return this.socketMsg$.asObservable();
  }
}
