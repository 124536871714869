import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarruselComponent } from './carrusel/carrusel.component';

@NgModule({
  declarations: [CarruselComponent],
  imports: [CommonModule],
  exports: [CarruselComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarruselModule {}
