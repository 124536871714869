var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// lib/geojson-equality.ts
import equal from "deep-equal";
var _GeojsonEquality = class _GeojsonEquality {
  constructor(opts) {
    this.direction = false;
    this.compareProperties = true;
    var _a, _b, _c;
    this.precision = 10 ** -((_a = opts == null ? void 0 : opts.precision) != null ? _a : 17);
    this.direction = (_b = opts == null ? void 0 : opts.direction) != null ? _b : false;
    this.compareProperties = (_c = opts == null ? void 0 : opts.compareProperties) != null ? _c : true;
  }
  compare(g1, g2) {
    if (g1.type !== g2.type) {
      return false;
    }
    if (!sameLength(g1, g2)) {
      return false;
    }
    switch (g1.type) {
      case "Point":
        return this.compareCoord(g1.coordinates, g2.coordinates);
      case "LineString":
        return this.compareLine(g1.coordinates, g2.coordinates);
      case "Polygon":
        return this.comparePolygon(g1, g2);
      case "GeometryCollection":
        return this.compareGeometryCollection(g1, g2);
      case "Feature":
        return this.compareFeature(g1, g2);
      case "FeatureCollection":
        return this.compareFeatureCollection(g1, g2);
      default:
        if (g1.type.startsWith("Multi")) {
          const g1s = explode(g1);
          const g2s = explode(g2);
          return g1s.every(g1part => g2s.some(g2part => this.compare(g1part, g2part)));
        }
    }
    return false;
  }
  compareCoord(c1, c2) {
    return c1.length === c2.length && c1.every((c, i) => Math.abs(c - c2[i]) < this.precision);
  }
  compareLine(path1, path2, ind = 0, isPoly = false) {
    if (!sameLength(path1, path2)) {
      return false;
    }
    const p1 = path1;
    let p2 = path2;
    if (isPoly && !this.compareCoord(p1[0], p2[0])) {
      const startIndex = this.fixStartIndex(p2, p1);
      if (!startIndex) {
        return false;
      } else {
        p2 = startIndex;
      }
    }
    const sameDirection = this.compareCoord(p1[ind], p2[ind]);
    if (this.direction || sameDirection) {
      return this.comparePath(p1, p2);
    } else {
      if (this.compareCoord(p1[ind], p2[p2.length - (1 + ind)])) {
        return this.comparePath(p1.slice().reverse(), p2);
      }
      return false;
    }
  }
  fixStartIndex(sourcePath, targetPath) {
    let correctPath,
      ind = -1;
    for (let i = 0; i < sourcePath.length; i++) {
      if (this.compareCoord(sourcePath[i], targetPath[0])) {
        ind = i;
        break;
      }
    }
    if (ind >= 0) {
      correctPath = [].concat(sourcePath.slice(ind, sourcePath.length), sourcePath.slice(1, ind + 1));
    }
    return correctPath;
  }
  comparePath(p1, p2) {
    return p1.every((c, i) => this.compareCoord(c, p2[i]));
  }
  comparePolygon(g1, g2) {
    if (this.compareLine(g1.coordinates[0], g2.coordinates[0], 1, true)) {
      const holes1 = g1.coordinates.slice(1, g1.coordinates.length);
      const holes2 = g2.coordinates.slice(1, g2.coordinates.length);
      return holes1.every(h1 => holes2.some(h2 => this.compareLine(h1, h2, 1, true)));
    }
    return false;
  }
  compareGeometryCollection(g1, g2) {
    return sameLength(g1.geometries, g2.geometries) && this.compareBBox(g1, g2) && g1.geometries.every((g, i) => this.compare(g, g2.geometries[i]));
  }
  compareFeature(g1, g2) {
    return g1.id === g2.id && (this.compareProperties ? equal(g1.properties, g2.properties) : true) && this.compareBBox(g1, g2) && this.compare(g1.geometry, g2.geometry);
  }
  compareFeatureCollection(g1, g2) {
    return sameLength(g1.features, g2.features) && this.compareBBox(g1, g2) && g1.features.every((f, i) => this.compare(f, g2.features[i]));
  }
  compareBBox(g1, g2) {
    return Boolean(!g1.bbox && !g2.bbox) || (g1.bbox && g2.bbox ? this.compareCoord(g1.bbox, g2.bbox) : false);
  }
};
__name(_GeojsonEquality, "GeojsonEquality");
var GeojsonEquality = _GeojsonEquality;
function sameLength(g1, g2) {
  return g1.coordinates ? g1.coordinates.length === g2.coordinates.length : g1.length === g2.length;
}
__name(sameLength, "sameLength");
function explode(g) {
  return g.coordinates.map(part => ({
    type: g.type.replace("Multi", ""),
    coordinates: part
  }));
}
__name(explode, "explode");

// index.ts
var earthRadius = 63710088e-1;
var factors = {
  centimeters: earthRadius * 100,
  centimetres: earthRadius * 100,
  degrees: 360 / (2 * Math.PI),
  feet: earthRadius * 3.28084,
  inches: earthRadius * 39.37,
  kilometers: earthRadius / 1e3,
  kilometres: earthRadius / 1e3,
  meters: earthRadius,
  metres: earthRadius,
  miles: earthRadius / 1609.344,
  millimeters: earthRadius * 1e3,
  millimetres: earthRadius * 1e3,
  nauticalmiles: earthRadius / 1852,
  radians: 1,
  yards: earthRadius * 1.0936
};
var areaFactors = {
  acres: 247105e-9,
  centimeters: 1e4,
  centimetres: 1e4,
  feet: 10.763910417,
  hectares: 1e-4,
  inches: 1550.003100006,
  kilometers: 1e-6,
  kilometres: 1e-6,
  meters: 1,
  metres: 1,
  miles: 386e-9,
  nauticalmiles: 29155334959812285e-23,
  millimeters: 1e6,
  millimetres: 1e6,
  yards: 1.195990046
};
function feature(geom, properties, options = {}) {
  const feat = {
    type: "Feature"
  };
  if (options.id === 0 || options.id) {
    feat.id = options.id;
  }
  if (options.bbox) {
    feat.bbox = options.bbox;
  }
  feat.properties = properties || {};
  feat.geometry = geom;
  return feat;
}
__name(feature, "feature");
function geometry(type, coordinates, _options = {}) {
  switch (type) {
    case "Point":
      return point(coordinates).geometry;
    case "LineString":
      return lineString(coordinates).geometry;
    case "Polygon":
      return polygon(coordinates).geometry;
    case "MultiPoint":
      return multiPoint(coordinates).geometry;
    case "MultiLineString":
      return multiLineString(coordinates).geometry;
    case "MultiPolygon":
      return multiPolygon(coordinates).geometry;
    default:
      throw new Error(type + " is invalid");
  }
}
__name(geometry, "geometry");
function point(coordinates, properties, options = {}) {
  if (!coordinates) {
    throw new Error("coordinates is required");
  }
  if (!Array.isArray(coordinates)) {
    throw new Error("coordinates must be an Array");
  }
  if (coordinates.length < 2) {
    throw new Error("coordinates must be at least 2 numbers long");
  }
  if (!isNumber(coordinates[0]) || !isNumber(coordinates[1])) {
    throw new Error("coordinates must contain numbers");
  }
  const geom = {
    type: "Point",
    coordinates
  };
  return feature(geom, properties, options);
}
__name(point, "point");
function points(coordinates, properties, options = {}) {
  return featureCollection(coordinates.map(coords => {
    return point(coords, properties);
  }), options);
}
__name(points, "points");
function polygon(coordinates, properties, options = {}) {
  for (const ring of coordinates) {
    if (ring.length < 4) {
      throw new Error("Each LinearRing of a Polygon must have 4 or more Positions.");
    }
    if (ring[ring.length - 1].length !== ring[0].length) {
      throw new Error("First and last Position are not equivalent.");
    }
    for (let j = 0; j < ring[ring.length - 1].length; j++) {
      if (ring[ring.length - 1][j] !== ring[0][j]) {
        throw new Error("First and last Position are not equivalent.");
      }
    }
  }
  const geom = {
    type: "Polygon",
    coordinates
  };
  return feature(geom, properties, options);
}
__name(polygon, "polygon");
function polygons(coordinates, properties, options = {}) {
  return featureCollection(coordinates.map(coords => {
    return polygon(coords, properties);
  }), options);
}
__name(polygons, "polygons");
function lineString(coordinates, properties, options = {}) {
  if (coordinates.length < 2) {
    throw new Error("coordinates must be an array of two or more positions");
  }
  const geom = {
    type: "LineString",
    coordinates
  };
  return feature(geom, properties, options);
}
__name(lineString, "lineString");
function lineStrings(coordinates, properties, options = {}) {
  return featureCollection(coordinates.map(coords => {
    return lineString(coords, properties);
  }), options);
}
__name(lineStrings, "lineStrings");
function featureCollection(features, options = {}) {
  const fc = {
    type: "FeatureCollection"
  };
  if (options.id) {
    fc.id = options.id;
  }
  if (options.bbox) {
    fc.bbox = options.bbox;
  }
  fc.features = features;
  return fc;
}
__name(featureCollection, "featureCollection");
function multiLineString(coordinates, properties, options = {}) {
  const geom = {
    type: "MultiLineString",
    coordinates
  };
  return feature(geom, properties, options);
}
__name(multiLineString, "multiLineString");
function multiPoint(coordinates, properties, options = {}) {
  const geom = {
    type: "MultiPoint",
    coordinates
  };
  return feature(geom, properties, options);
}
__name(multiPoint, "multiPoint");
function multiPolygon(coordinates, properties, options = {}) {
  const geom = {
    type: "MultiPolygon",
    coordinates
  };
  return feature(geom, properties, options);
}
__name(multiPolygon, "multiPolygon");
function geometryCollection(geometries, properties, options = {}) {
  const geom = {
    type: "GeometryCollection",
    geometries
  };
  return feature(geom, properties, options);
}
__name(geometryCollection, "geometryCollection");
function round(num, precision = 0) {
  if (precision && !(precision >= 0)) {
    throw new Error("precision must be a positive number");
  }
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(num * multiplier) / multiplier;
}
__name(round, "round");
function radiansToLength(radians, units = "kilometers") {
  const factor = factors[units];
  if (!factor) {
    throw new Error(units + " units is invalid");
  }
  return radians * factor;
}
__name(radiansToLength, "radiansToLength");
function lengthToRadians(distance, units = "kilometers") {
  const factor = factors[units];
  if (!factor) {
    throw new Error(units + " units is invalid");
  }
  return distance / factor;
}
__name(lengthToRadians, "lengthToRadians");
function lengthToDegrees(distance, units) {
  return radiansToDegrees(lengthToRadians(distance, units));
}
__name(lengthToDegrees, "lengthToDegrees");
function bearingToAzimuth(bearing) {
  let angle = bearing % 360;
  if (angle < 0) {
    angle += 360;
  }
  return angle;
}
__name(bearingToAzimuth, "bearingToAzimuth");
function radiansToDegrees(radians) {
  const degrees = radians % (2 * Math.PI);
  return degrees * 180 / Math.PI;
}
__name(radiansToDegrees, "radiansToDegrees");
function degreesToRadians(degrees) {
  const radians = degrees % 360;
  return radians * Math.PI / 180;
}
__name(degreesToRadians, "degreesToRadians");
function convertLength(length, originalUnit = "kilometers", finalUnit = "kilometers") {
  if (!(length >= 0)) {
    throw new Error("length must be a positive number");
  }
  return radiansToLength(lengthToRadians(length, originalUnit), finalUnit);
}
__name(convertLength, "convertLength");
function convertArea(area, originalUnit = "meters", finalUnit = "kilometers") {
  if (!(area >= 0)) {
    throw new Error("area must be a positive number");
  }
  const startFactor = areaFactors[originalUnit];
  if (!startFactor) {
    throw new Error("invalid original units");
  }
  const finalFactor = areaFactors[finalUnit];
  if (!finalFactor) {
    throw new Error("invalid final units");
  }
  return area / startFactor * finalFactor;
}
__name(convertArea, "convertArea");
function isNumber(num) {
  return !isNaN(num) && num !== null && !Array.isArray(num);
}
__name(isNumber, "isNumber");
function isObject(input) {
  return input !== null && typeof input === "object" && !Array.isArray(input);
}
__name(isObject, "isObject");
function validateBBox(bbox) {
  if (!bbox) {
    throw new Error("bbox is required");
  }
  if (!Array.isArray(bbox)) {
    throw new Error("bbox must be an Array");
  }
  if (bbox.length !== 4 && bbox.length !== 6) {
    throw new Error("bbox must be an Array of 4 or 6 numbers");
  }
  bbox.forEach(num => {
    if (!isNumber(num)) {
      throw new Error("bbox must only contain numbers");
    }
  });
}
__name(validateBBox, "validateBBox");
function validateId(id) {
  if (!id) {
    throw new Error("id is required");
  }
  if (["string", "number"].indexOf(typeof id) === -1) {
    throw new Error("id must be a number or a string");
  }
}
__name(validateId, "validateId");
export { GeojsonEquality, areaFactors, bearingToAzimuth, convertArea, convertLength, degreesToRadians, earthRadius, factors, feature, featureCollection, geometry, geometryCollection, isNumber, isObject, lengthToDegrees, lengthToRadians, lineString, lineStrings, multiLineString, multiPoint, multiPolygon, point, points, polygon, polygons, radiansToDegrees, radiansToLength, round, validateBBox, validateId };
