import { Injectable } from '@angular/core';
import { ICreatePortico, IListado, IQueryParam, IPortico } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class PorticosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IPortico>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/porticos`, { params });
  }

  public listarPorId(id: string): Observable<IPortico> {
    return this.http.get(`/porticos/${id}`);
  }

  public crear(dato: ICreatePortico): Observable<IPortico> {
    return this.http.post(`/porticos`, dato);
  }

  public editar(id: string, dato: ICreatePortico): Observable<IPortico> {
    return this.http.put(`/porticos/${id}`, dato);
  }

  public eliminar(id: string): Observable<IPortico> {
    return this.http.delete(`/porticos/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve, reject) => {
      const params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/porticos/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'porticos.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }

  public subirImagenPublica(
    file: File,
    fileName: string,
  ): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return this.http.post(`/clientes/subirImagen`, formData);
  }
}
