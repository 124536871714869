'use strict';

var GetIntrinsic = require('get-intrinsic');
var callBound = require('call-bind/callBound');
var $WeakSet = GetIntrinsic('%WeakSet%', true);
var $setHas = callBound('WeakSet.prototype.has', true);
if ($setHas) {
  var $mapHas = callBound('WeakMap.prototype.has', true);

  /** @type {import('.')} */
  module.exports = function isWeakSet(x) {
    if (!x || typeof x !== 'object') {
      return false;
    }
    try {
      $setHas(x, $setHas);
      if ($mapHas) {
        try {
          $mapHas(x, $mapHas);
        } catch (e) {
          return true;
        }
      }
      // @ts-expect-error TS can't figure out that $WeakSet is always truthy here
      return x instanceof $WeakSet; // core-js workaround, pre-v3
    } catch (e) {}
    return false;
  };
} else {
  /** @type {import('.')} */
  // eslint-disable-next-line no-unused-vars
  module.exports = function isWeakSet(x) {
    // `WeakSet` does not exist, or does not have a `has` method
    return false;
  };
}