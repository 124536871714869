var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});

// index.ts
import { bbox as calcBbox } from "@turf/bbox";
import { booleanPointOnLine } from "@turf/boolean-point-on-line";
import { booleanPointInPolygon } from "@turf/boolean-point-in-polygon";
import { getGeom } from "@turf/invariant";
function booleanWithin(feature1, feature2) {
  var geom1 = getGeom(feature1);
  var geom2 = getGeom(feature2);
  var type1 = geom1.type;
  var type2 = geom2.type;
  switch (type1) {
    case "Point":
      switch (type2) {
        case "MultiPoint":
          return isPointInMultiPoint(geom1, geom2);
        case "LineString":
          return booleanPointOnLine(geom1, geom2, {
            ignoreEndVertices: true
          });
        case "Polygon":
        case "MultiPolygon":
          return booleanPointInPolygon(geom1, geom2, {
            ignoreBoundary: true
          });
        default:
          throw new Error("feature2 " + type2 + " geometry not supported");
      }
    case "MultiPoint":
      switch (type2) {
        case "MultiPoint":
          return isMultiPointInMultiPoint(geom1, geom2);
        case "LineString":
          return isMultiPointOnLine(geom1, geom2);
        case "Polygon":
        case "MultiPolygon":
          return isMultiPointInPoly(geom1, geom2);
        default:
          throw new Error("feature2 " + type2 + " geometry not supported");
      }
    case "LineString":
      switch (type2) {
        case "LineString":
          return isLineOnLine(geom1, geom2);
        case "Polygon":
        case "MultiPolygon":
          return isLineInPoly(geom1, geom2);
        default:
          throw new Error("feature2 " + type2 + " geometry not supported");
      }
    case "Polygon":
      switch (type2) {
        case "Polygon":
        case "MultiPolygon":
          return isPolyInPoly(geom1, geom2);
        default:
          throw new Error("feature2 " + type2 + " geometry not supported");
      }
    default:
      throw new Error("feature1 " + type1 + " geometry not supported");
  }
}
__name(booleanWithin, "booleanWithin");
function isPointInMultiPoint(point, multiPoint) {
  var i;
  var output = false;
  for (i = 0; i < multiPoint.coordinates.length; i++) {
    if (compareCoords(multiPoint.coordinates[i], point.coordinates)) {
      output = true;
      break;
    }
  }
  return output;
}
__name(isPointInMultiPoint, "isPointInMultiPoint");
function isMultiPointInMultiPoint(multiPoint1, multiPoint2) {
  for (var i = 0; i < multiPoint1.coordinates.length; i++) {
    var anyMatch = false;
    for (var i2 = 0; i2 < multiPoint2.coordinates.length; i2++) {
      if (compareCoords(multiPoint1.coordinates[i], multiPoint2.coordinates[i2])) {
        anyMatch = true;
      }
    }
    if (!anyMatch) {
      return false;
    }
  }
  return true;
}
__name(isMultiPointInMultiPoint, "isMultiPointInMultiPoint");
function isMultiPointOnLine(multiPoint, lineString) {
  var foundInsidePoint = false;
  for (var i = 0; i < multiPoint.coordinates.length; i++) {
    if (!booleanPointOnLine(multiPoint.coordinates[i], lineString)) {
      return false;
    }
    if (!foundInsidePoint) {
      foundInsidePoint = booleanPointOnLine(multiPoint.coordinates[i], lineString, {
        ignoreEndVertices: true
      });
    }
  }
  return foundInsidePoint;
}
__name(isMultiPointOnLine, "isMultiPointOnLine");
function isMultiPointInPoly(multiPoint, polygon) {
  var output = true;
  var oneInside = false;
  var isInside = false;
  for (var i = 0; i < multiPoint.coordinates.length; i++) {
    isInside = booleanPointInPolygon(multiPoint.coordinates[i], polygon);
    if (!isInside) {
      output = false;
      break;
    }
    if (!oneInside) {
      isInside = booleanPointInPolygon(multiPoint.coordinates[i], polygon, {
        ignoreBoundary: true
      });
    }
  }
  return output && isInside;
}
__name(isMultiPointInPoly, "isMultiPointInPoly");
function isLineOnLine(lineString1, lineString2) {
  for (var i = 0; i < lineString1.coordinates.length; i++) {
    if (!booleanPointOnLine(lineString1.coordinates[i], lineString2)) {
      return false;
    }
  }
  return true;
}
__name(isLineOnLine, "isLineOnLine");
function isLineInPoly(linestring, polygon) {
  var polyBbox = calcBbox(polygon);
  var lineBbox = calcBbox(linestring);
  if (!doBBoxOverlap(polyBbox, lineBbox)) {
    return false;
  }
  var foundInsidePoint = false;
  for (var i = 0; i < linestring.coordinates.length; i++) {
    if (!booleanPointInPolygon(linestring.coordinates[i], polygon)) {
      return false;
    }
    if (!foundInsidePoint) {
      foundInsidePoint = booleanPointInPolygon(linestring.coordinates[i], polygon, {
        ignoreBoundary: true
      });
    }
    if (!foundInsidePoint && i < linestring.coordinates.length - 1) {
      var midpoint = getMidpoint(linestring.coordinates[i], linestring.coordinates[i + 1]);
      foundInsidePoint = booleanPointInPolygon(midpoint, polygon, {
        ignoreBoundary: true
      });
    }
  }
  return foundInsidePoint;
}
__name(isLineInPoly, "isLineInPoly");
function isPolyInPoly(geometry1, geometry2) {
  var poly1Bbox = calcBbox(geometry1);
  var poly2Bbox = calcBbox(geometry2);
  if (!doBBoxOverlap(poly2Bbox, poly1Bbox)) {
    return false;
  }
  for (var i = 0; i < geometry1.coordinates[0].length; i++) {
    if (!booleanPointInPolygon(geometry1.coordinates[0][i], geometry2)) {
      return false;
    }
  }
  return true;
}
__name(isPolyInPoly, "isPolyInPoly");
function doBBoxOverlap(bbox1, bbox2) {
  if (bbox1[0] > bbox2[0]) return false;
  if (bbox1[2] < bbox2[2]) return false;
  if (bbox1[1] > bbox2[1]) return false;
  if (bbox1[3] < bbox2[3]) return false;
  return true;
}
__name(doBBoxOverlap, "doBBoxOverlap");
function compareCoords(pair1, pair2) {
  return pair1[0] === pair2[0] && pair1[1] === pair2[1];
}
__name(compareCoords, "compareCoords");
function getMidpoint(pair1, pair2) {
  return [(pair1[0] + pair2[0]) / 2, (pair1[1] + pair2[1]) / 2];
}
__name(getMidpoint, "getMidpoint");
var turf_boolean_within_default = booleanWithin;
export { booleanWithin, turf_boolean_within_default as default };
