import { Injectable } from '@angular/core';
import { IApiKey, IListado, IQueryParam } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApikeyService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IApiKey>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/apikeys`, { params });
  }

  public listarPorId(id: string): Observable<IApiKey> {
    return this.http.get(`/apikeys/${id}`);
  }
}
