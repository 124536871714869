import { Injectable } from '@angular/core';
import {
  IListado,
  IPunto,
  IQueryParam,
  ICreatePunto,
  IUpdatePunto,
  ICoordenadas,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { PAIS } from 'src/environments/contantes';

@Injectable({
  providedIn: 'root',
})
export class PuntosService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IPunto>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/puntos`, { params });
  }

  public listarPorId(id: string): Observable<IPunto> {
    return this.http.get(`/puntos/${id}`);
  }

  public crear(dato: ICreatePunto): Observable<IPunto> {
    return this.http.post(`/puntos`, dato);
  }

  public editar(id: string, dato: IUpdatePunto): Observable<IPunto> {
    return this.http.put(`/puntos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/puntos/${id}`);
  }

  public direcciones(
    text: string,
    coordenadas?: ICoordenadas
  ): Observable<{ resultados: string[] }> {
    return this.http.post(`/puntos/direcciones`, { text, PAIS, coordenadas });
  }

  public geocode(text: string): Observable<ICoordenadas> {
    return this.http.post(`/puntos/geocode`, { text });
  }
}
