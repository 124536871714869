import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FiltroTablaComponent } from './filtro-tabla/filtro-tabla.component';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [FiltroTablaComponent],
  imports: [CommonModule, FormsModule, MaterialModule, NgSelectModule],
  exports: [FiltroTablaComponent],
})
export class FiltroTablaModule {}
