import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import Boost from 'highcharts/modules/boost';
import SolidGauge from 'highcharts/modules/solid-gauge';
import more from 'highcharts/highcharts-more';
import HC_stock from 'highcharts/modules/stock';
import IndicatorsCore from 'highcharts/indicators/indicators';
import IndicatorRegressions from 'highcharts/indicators/regressions';
import { Options } from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import xrange from 'highcharts/modules/xrange';
import ExportData from 'highcharts/modules/export-data';
import theme from 'highcharts/themes/sunset';

theme(Highcharts);
HC_stock(Highcharts);
noData(Highcharts);
more(Highcharts);
Boost(Highcharts);
SolidGauge(Highcharts);
IndicatorsCore(Highcharts);
IndicatorRegressions(Highcharts);
xrange(Highcharts);
// Exporting(Highcharts);
// ExportData(Highcharts);

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  public Highcharts: typeof Highcharts = Highcharts;

  @Input() options!: Options;
  @Input() style: string | undefined;
  @Input() constructorType: string = 'chart';
  public update: boolean = false;
  chart?: Highcharts.Chart;

  @Output() optionsChange = new EventEmitter<Options>();
  @Output() chartPrint = new EventEmitter<void>();

  constructor() {
    this.chartCallback.bind(this);
  }

  public chartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }

  public chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    setTimeout(() => {
      this.chartPrint.emit();
    }, 100);
  };

  ngOnChanges() {
    this.update = true;
    if (this.chart) {
      this.chart?.update(this.options, true, true);
    }
  }

  ngOnInit(): void {}
}
