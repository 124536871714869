function pointInPolygon(p, polygon) {
  var i = 0;
  var ii = 0;
  var k = 0;
  var f = 0;
  var u1 = 0;
  var v1 = 0;
  var u2 = 0;
  var v2 = 0;
  var currentP = null;
  var nextP = null;
  var x = p[0];
  var y = p[1];
  var numContours = polygon.length;
  for (i; i < numContours; i++) {
    ii = 0;
    var contourLen = polygon[i].length - 1;
    var contour = polygon[i];
    currentP = contour[0];
    if (currentP[0] !== contour[contourLen][0] && currentP[1] !== contour[contourLen][1]) {
      throw new Error('First and last coordinates in a ring must be the same');
    }
    u1 = currentP[0] - x;
    v1 = currentP[1] - y;
    for (ii; ii < contourLen; ii++) {
      nextP = contour[ii + 1];
      v2 = nextP[1] - y;
      if (v1 < 0 && v2 < 0 || v1 > 0 && v2 > 0) {
        currentP = nextP;
        v1 = v2;
        u1 = currentP[0] - x;
        continue;
      }
      u2 = nextP[0] - p[0];
      if (v2 > 0 && v1 <= 0) {
        f = u1 * v2 - u2 * v1;
        if (f > 0) {
          k = k + 1;
        } else if (f === 0) {
          return 0;
        }
      } else if (v1 > 0 && v2 <= 0) {
        f = u1 * v2 - u2 * v1;
        if (f < 0) {
          k = k + 1;
        } else if (f === 0) {
          return 0;
        }
      } else if (v2 === 0 && v1 < 0) {
        f = u1 * v2 - u2 * v1;
        if (f === 0) {
          return 0;
        }
      } else if (v1 === 0 && v2 < 0) {
        f = u1 * v2 - u2 * v1;
        if (f === 0) {
          return 0;
        }
      } else if (v1 === 0 && v2 === 0) {
        if (u2 <= 0 && u1 >= 0) {
          return 0;
        } else if (u1 <= 0 && u2 >= 0) {
          return 0;
        }
      }
      currentP = nextP;
      v1 = v2;
      u1 = u2;
    }
  }
  if (k % 2 === 0) {
    return false;
  }
  return true;
}
export default pointInPolygon;