import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkerLocationComponent } from './marker-location/marker-location.component';
import { MarkerLocationService } from './marker-location.service';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [MarkerLocationComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [MarkerLocationComponent],
  providers: [MarkerLocationService],
})
export class MarkerLocationModule {}
