import { Injectable } from '@angular/core';
import { IControl, IListado, IQueryParam } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ControlesService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IControl>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/controles`, { params });
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve, reject) => {
      let params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/controles/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'controles.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          }
        );
    });
  }

  public listarPorIdVecino(idVecino: string): Observable<IControl> {
    return this.http.get(`/controles/idVecino/${idVecino}`);
  }
}
