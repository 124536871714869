import { Injectable } from '@angular/core';
import {
  IListado,
  INuevaZonaInput,
  IQueryParam,
  IZona,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ZonasService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IZona>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/zonas`, { params });
  }

  public listarPorId(id: string): Observable<IZona> {
    return this.http.get(`/zonas/${id}`);
  }

  public crear(dato: INuevaZonaInput): Observable<IZona> {
    return this.http.post(`/zonas`, dato);
  }

  public editar(id: string, dato: INuevaZonaInput): Observable<IZona> {
    return this.http.put(`/zonas/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/zonas/${id}`);
  }
}
