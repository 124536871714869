import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../entidades/login/auth.service';
import { StoreService } from '../../entidades/login/store.service';
import { saveAs } from 'file-saver';
import { NOMBRE_APP, VERSION } from '../../../environments/environment';

export interface IHttpRequestOptions {
  headers?: HttpHeaders;
  context?: HttpContext;
  observe?: 'body' | 'events' | 'response' | any;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json' | any;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private storeService: StoreService,
  ) {}

  public getFile(
    url: string,
    filename: string,
    params?: HttpParams,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.get(url, { params, responseType: 'blob' }).subscribe(
        (response: any) => {
          try {
            const file = new File([response], filename);
            saveAs(file);
            resolve();
          } catch (error) {
            console.error(error);
            resolve();
          }
        },
        (error: any) => {
          const reader: FileReader = new FileReader();
          reader.onloadend = () => {
            reject(JSON.parse(reader.result as string));
          };
          reader.readAsText(error.error);
        },
      );
    });
  }

  public getFile2(
    url: string,
    filename: string,
    params?: HttpParams,
  ): Promise<File> {
    return new Promise((resolve, reject) => {
      this.get(url, { params, responseType: 'blob' }).subscribe(
        (response: any) => {
          try {
            const file = new File([response], filename);
            resolve(file);
          } catch (error) {
            console.error(error);
            return;
          }
        },
        (error: any) => {
          const reader: FileReader = new FileReader();
          reader.onloadend = () => {
            reject(JSON.parse(reader.result as string));
          };
          reader.readAsText(error.error);
        },
      );
    });
  }

  public get(ruta: string, options?: IHttpRequestOptions): Observable<any> {
    return new Observable((observer) => {
      this._get(ruta, options)
        .then((resp) => {
          observer.next(resp);
          observer.complete();
        })
        .catch((err) => {
          if (err.status === 401 && this.storeService.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._get(ruta, options)
                  .then((resp) => {
                    observer.next(resp);
                    observer.complete();
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public post(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions,
  ): Observable<any> {
    return new Observable((observer) => {
      this._post(ruta, body, options)
        .then((resp) => {
          observer.next(resp);
        })
        .catch((err) => {
          if (err.status === 401 && this.storeService.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._post(ruta, body, options)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public put(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions,
  ): Observable<any> {
    return new Observable((observer) => {
      this._put(ruta, body, options)
        .then((resp) => {
          observer.next(resp);
        })
        .catch((err) => {
          if (err.status === 401 && this.storeService.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._put(ruta, body, options)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  public delete(ruta: string): Observable<any> {
    return new Observable((observer) => {
      this._delete(ruta)
        .then((resp) => {
          observer.next(resp);
        })
        .catch((err) => {
          if (err.status === 401 && this.storeService.getRefreshToken()) {
            console.log('refreshing token');
            this.auth
              .refreshToken()
              .then(() => {
                this._delete(ruta)
                  .then((resp) => {
                    observer.next(resp);
                  })
                  .catch((refreshErr) => {
                    observer.error(refreshErr);
                  });
              })
              .catch((refreshErr) => {
                observer.error(refreshErr);
              });
          } else {
            observer.error(err);
          }
        });
    });
  }

  private _get<T>(ruta: string, options?: IHttpRequestOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      // Seteo el header de autorizacion
      const token = this.storeService.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      options = options || {};
      options.headers = options.headers || new HttpHeaders();

      for (const key of headers.keys()) {
        options.headers = options.headers.append(key, headers.get(key)!);
      }

      // Request
      this.http.get<T>(ruta, options).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private _post(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.storeService.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      options = options || {};
      options.headers = options.headers || new HttpHeaders();

      for (const key of headers.keys()) {
        options.headers = options.headers.append(key, headers.get(key)!);
      }

      const req = this.http.post<any>(ruta, body, options);
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private _put(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.storeService.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });

      options = options || {};
      options.headers = options.headers || new HttpHeaders();

      for (const key of headers.keys()) {
        options.headers = options.headers.append(key, headers.get(key)!);
      }

      const req = this.http.put<any>(ruta, body, options);
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  private _delete(ruta: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const token = this.storeService.getToken();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      });
      const req = this.http.delete<any>(ruta, { headers });
      req.subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }
}
