<div class="p-3">
  <span
    *ngIf="grabando !== 'recording'"
    style="display: flex; align-items: center; font-size: 1.2em"
    (click)="grabar()"
  >
    <button mat-mini-fab color="warn">
      <mat-icon>mic</mat-icon>
    </button>
    <span class="px-2">Enviar</span>
  </span>

  <span
    *ngIf="grabando === 'recording'"
    style="
      display: flex;
      align-items: center;
      font-size: 1.2em;
      white-space: nowrap;
    "
  >
    <span (click)="stop()">
      <button mat-mini-fab color="primary">
        <mat-icon>stop</mat-icon>
      </button>

      <span class="px-2">Detener</span>
    </span>
  </span>
</div>
