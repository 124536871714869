import { Injectable } from '@angular/core';
import {
  IListado,
  ICreateTokenVecino,
  IQueryParam,
  ITokenVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import saveAs from 'file-saver';

@Injectable({ providedIn: 'root' })
export class TokenVecinosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ITokenVecino>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/tokenvecinos`, { params });
  }

  public crear(dato: ICreateTokenVecino): Observable<ITokenVecino> {
    return this.http.post(`/tokenvecinos`, dato);
  }

  public listarPorId(id: string): Observable<ITokenVecino> {
    return this.http.get(`/tokenvecinos/${id}`);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/tokenvecinos/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/tokenvecinos/exportarTokensVecinos`, {
          params,
          responseType: 'blob',
        })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'tokens_vecinos.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }
}
