import { Injectable } from '@angular/core';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PromptComponent } from './prompt/prompt.component';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'Aceptar',
    btnCancelText: string = 'Cancelar'
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const modal = this.matDialog.open(ConfirmationComponent, {
        width: '500px',
        data: {
          title,
          message,
          btnOkText,
          btnCancelText,
        },
        disableClose: true,
      });

      modal.afterClosed().subscribe((response) => {
        if (response) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public dialog(
    title: string,
    message: string,
    btnCancelText: string = 'Volver'
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const modal = this.matDialog.open(DialogComponent, {
        width: '500px',
        data: {
          title,
          message,
          btnCancelText,
        },
        disableClose: true,
      });

      modal.afterClosed().subscribe((response) => {
        if (response) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public prompt(
    title: string,
    message: string,
    inputName: string,
    btnOkText: string = 'Aceptar',
    btnCancelText: string = 'Cancelar'
  ): Promise<string | false> {
    return new Promise((resolve, reject) => {
      const modal = this.matDialog.open(PromptComponent, {
        width: '500px',
        data: {
          title,
          message,
          inputName,
          btnOkText,
          btnCancelText,
        },
        disableClose: true,
      });

      modal.afterClosed().subscribe((response) => {
        if (response) {
          resolve(response);
        } else {
          resolve(false);
        }
      });
    });
  }
}
