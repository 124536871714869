import { Injectable } from '@angular/core';
import { ICategoriaVecino, IListado, IQueryParam } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriasVecinosService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<ICategoriaVecino>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/categoriasVecinos`, { params });
  }
}
