import { Injectable } from '@angular/core';
import {
  IListado,
  IBarrio,
  IQueryParam,
  ICreateBarrio,
  IUpdateBarrio,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class BarriosService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IBarrio>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/barrios`, { params });
  }

  public listarPorId(id: string): Observable<IBarrio> {
    return this.http.get(`/barrios/${id}`);
  }

  public crear(dato: ICreateBarrio): Observable<IBarrio> {
    return this.http.post(`/barrios`, dato);
  }

  public editar(id: string, dato: IUpdateBarrio): Observable<IBarrio> {
    return this.http.put(`/barrios/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/barrios/${id}`);
  }
}
